import React, { useEffect, useState } from 'react';
import './CSS/App.css';
import Button from '@mui/material/Button';
import SvgConverter from './SvgConverter';
import Trademark from './Trademark';
import SvgAdminPanel from './SvgAdminPanel';
import AppScreen from './App';
import LoginScreen from './LoginScreen';
import MaintenanceScreen from './MaintenanceScreen';
import ShopEffort from './ShopEffort';

import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useUser } from "./Context/UserContext";
import Box from "@mui/material/Box";
import { Height } from '@mui/icons-material';
import {  Card, CardContent, Typography,Avatar } from "@mui/material";
const CustomButton = styled(Button)({
  borderColor: 'black',
  color: 'black',
  border: '2px solid #1A1A1A',
  borderRadius: '15px',
  boxSizing: 'border-box',
  fontFamily: 'Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontSize: '16px',
  fontWeight: 600,
  minHeight: '60px',
  padding: '16px 24px',
  transition: 'all 300ms cubic-bezier(.23, 1, 0.32, 1)',
  width: '100%',
  marginBottom: '6%',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#1A1A1A',
    borderColor: 'rgba(121, 147, 81)',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0 8px 15px',
    transform: 'translateY(-2px)',
  },
  '&:active': {
    boxShadow: 'none',
    transform: 'translateY(0)',
  },
  '&:disabled': {
    pointerEvents: 'none',
  },
});
const Menu = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const handleLogout = () => {
    setUser(null); // Kullanıcı bilgisini temizle
    navigate("/LoginScreen"); // Kullanıcıyı giriş sayfasına yönlendir
  };
  const imageList = [
    '/Logos/HORSE.svg'
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true); 

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => 
          (prevIndex + 1) % imageList.length 
        );
        setIsVisible(true); 
      }, 1000); 
      
    }, 40000); // 4 saniye

    return () => clearInterval(intervalId);
  }, [imageList.length]);

  return (
    <div className='OuterContainer'>
      <div className='MenuContainer'>
      <Avatar
          src="/Logos/HORSE HEAD.svg"
          alt="Ana Menü"
          sx={{ width: 350, height: 350 }}
        />
        <MenuComponent />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
      <Card sx={{
        backgroundColor: "#f0f0f0", // Hafif gri arka plan
        border: "2px solid black", // Siyah çerçeve
        borderRadius: "12px", // Yuvarlatılmış kenarlar
        p: -1,
        minWidth: 320,
        textAlign: "center",
        boxShadow: "none", // Gölgeyi kaldırarak düz tasarım
      }}>
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
            Welcome: {user?.Username}
          </Typography>
          <Button
            variant="contained"
            onClick={handleLogout}
            sx={{
              mt: 2,
              border: "2px solid black", // Menü butonlarına uygun siyah çerçeve
              color: "black",
              backgroundColor: "white",
              borderRadius: "10px",
              fontWeight: "bold",
              width: "100%",
              p: 1.5,
              "&:hover": {
                backgroundColor: "#e0e0e0", // Hover'da biraz koyulaşan arka plan
              },
            }}
          >
            Çıkış Yap
          </Button>
        </CardContent>
      </Card>
    </Box>
      </div>
      <div className="Divider"></div>
      <div className='LogoContainer'>
        <img 
          src={imageList[currentImageIndex]} 
          alt='Logo' 
          className={`LoopImage ${isVisible ? 'visible' : 'hidden'}`} 
        />
      </div>
    </div>
  );
};

const MenuComponent = () => {
  const navigate = useNavigate(); // useNavigate hook'u ile yönlendirme yapılır.
  const { user } = useUser(); 
console.log(user);
  const buttons = [
    {
      id: "Converter",
      label: "Dizayn Dönüştür",
      condition: user?.Admin === 1 || user?.DesignConverter === 1,
    },
    {
      id: "SvgAdminPanel",
      label: "Dizayn Tanımla",
      condition: user?.Admin === 1 || user?.DesignAdmin === 1,
    },
    {
      id: "Trademark",
      label: "Trademark",
      condition: user?.Admin === 1 || user?.TradeMark === 1,
    },
    {
      id: "MaintenanceScreen",
      label: "Bakım Ekranları",
      condition: user?.Admin === 1,
    },
    {
      id: "UserEffort",
      label: "Efor Tanımlama",
      condition: user?.Admin === 1|| user?.UserEffort === 1,
    },
    {
      id: "ShopEffort",
      label: "Shop Veri Giriş",
      condition: user?.Admin === 1 || user?.ShopEffort === 1,
    },
    {
      id: "UserReport",
      label: "Kullanıcı Raporları",
      condition: user?.Admin === 1 || user?.Reports === 1,
    },
  ];
  const authorizedButtons = buttons.filter((button) => button.condition); 
  const handleNavigation = (id) => {
    console.log(id);
    if (id === "Converter") {
      navigate('/converter');
    } else if (id === "Trademark") {
      navigate('/trademark');
    }
    else if(id === "SvgAdminPanel"){
      navigate('/SvgAdminPanel');
    }
    else if(id === "LoginScreen"){
      navigate('/LoginScreen');
    }
    else if(id === "MaintenanceScreen"){
      navigate('/MaintenanceScreen');
    } 
    else if(id === "UserEffort"){
      navigate('/UserEffort');
    }
    else if(id === "ShopEffort"){
      navigate('/ShopEffort');
    }
    else if(id === "UserReport"){
      navigate('/UserReports');
    } 
  };
  
  return (
    <>
<Box
  sx={{
    maxHeight: "600px", // Maksimum yükseklik burada tanımlanır
    overflowY: "auto",  // Dikey kaydırma aktif edilir
    border: "1px solid #ddd", // Opsiyonel, kutu kenarlığı ekleyebilirsiniz
    padding: "16px",   // İç boşluk
    borderRadius: "8px", // Köşeleri yuvarlama
  }}
>
  {authorizedButtons.length > 0 ? (
    authorizedButtons.map((button) => (
      <CustomButton
        key={button.id}
        id={button.id}
        onClick={() => handleNavigation(button.id)}
      >
        {button.label}
      </CustomButton>
    ))
  ) : (
    <div style={{ textAlign: "center", marginTop: "20px", color: "red" }}>
      Hiçbir yetkiniz bulunmamaktadır. Lütfen yöneticiniz ile iletişime geçiniz.
    </div>
  )}
</Box>
 
    
    </>
  );
};

export default Menu;
