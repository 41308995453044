import React, { useEffect, useState, useCallback } from "react";
import "./CSS/UserEffort.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  Backdrop,
  TextField,
  Checkbox,
  TextareaAutosize,
  Divider,
} from "@mui/material";
import axios from "axios";
import { Alert, AlertTitle } from "@mui/material";
import {
  Typography,
  Paper,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useUser } from "./Context/UserContext";
import { Snackbar } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  FormLabel,
  FormGroup,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { isSameDay, set } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FileUpload } from "primereact/fileupload";
import { Card } from "primereact/card";
import { Tag } from "primereact/tag";
import "primereact/resources/themes/saga-blue/theme.css"; // Tema CSS
import "primereact/resources/primereact.min.css"; // PrimeReact bileşen stilleri
import "primeicons/primeicons.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { use } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

const UserEffort = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [filterDate, setFilterDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const { user } = useUser();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [selectedEffortValue, setSelectedEffortValue] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isFolderVisible, setIsFolderVisible] = useState(false);
  const [isPiaceVisible, setIsPiaceVisible] = useState(false);
  const [AllUsers, setAllUsers] = useState([]);
  const [AdminSelectedUser, setAdminSelectedUser] = useState(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [nowInIstanbul, setNowInIstanbul] = useState(
    dayjs().tz("Europe/Istanbul")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setNowInIstanbul(dayjs().tz("Europe/Istanbul"));
    }, 600000);

    return () => clearInterval(interval); // Temizlik işlemi
  }, []);

  const effortOptions = [
    "MESAJLARA CEVAP",
    "CUSTOMHUB",
    "CUSTOM ORDER HAZIRLANMASI",
    "URUN BULMA",
    "URUN YUKLEME",
    "MAGAZA GENEL KONTROL",
    "TOPLANTI",
    "PIYASA ARSTIRMASI AR-GE",
    "REKLAM OPTIMIZASYONU",
    "URETIM TAKIP VE KONTROL",
    "DIZAYN CIZIMI",
    "MOCKUP EKLEME",
    "TRADEMARK KONTROLU",
    "ARGE",
    "FASHION FOLLOW",
    "YONETIM VE KONTROL",
    "DIGER",
  ];
  const handleEffortChange = (event) => {
    console.log(event.target.value);
    const value = event.target.value;
    setSelectedEffortValue(
      typeof value === "string" ? value.split(",") : value
    );
    console.log(value);
    setIsVisible(value.includes("DIGER") || value.includes("DIZAYN CIZIMI"));

    setIsFolderVisible(
      value.includes("URUN YUKLEME") ||
        value.includes("DIGER") ||
        value.includes("URUN BULMA")
    );

    setIsPiaceVisible(value.includes("DIZAYN CIZIMI"));
  };

  const handleUpdateEffortChange = (event) => {
    const value = event.target.value;
    const newEffortValues =
      typeof value === "string" ? value.split(",") : value;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      effortType: newEffortValues,
    }));
  };

  const today = new Date();

  const calculateTimeDifference = () => {
    if (startTime && endTime) {
      const start = dayjs(startTime);
      const end = dayjs(endTime);
      return end.diff(start, "minute"); // Dakika farkı
    }
    return null;
  };

  const calculateTimeDifferenceUpdate = () => {
    if (formValues.startTime && formValues.endTime) {
      const start = dayjs(formValues.startTime);
      const end = dayjs(formValues.endTime);
      return end.diff(start, "minute"); // Dakika farkı
    }
    return null;
  };

  const [tableData, setTableData] = useState([]);
  const [monthlyEffort, setmonthlyEffort] = useState([]);

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.post(path + "/api/GetDailyEffort", {
          UserID: user?.ID,
          date: filterDate.format("YYYY-MM-DD"),
          UserIsAdmin: user?.ReportAdmin,
        });
        const parsedData = response.data;
        setTableData(parsedData);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchShopData();
  }, [filterDate]);

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await axios.get(path + "/api/GetActivePersonnels", {});
        const parsedData = response.data;
        console.log(parsedData);
        setAllUsers(parsedData);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };

    fetchShopData();
  }, []);

  useEffect(() => {
    const fetchMontylEffort = async () => {
      try {
        const response = await axios.post(path + "/api/GetMonthlyUserEfforts", {
          UserID: user?.ID,
          month: filterDate.format("MM"),
          year: filterDate.format("YYYY"),
        });
        const parsedData = response.data;
        setmonthlyEffort(parsedData);
      } catch (error) {
        console.error("Error fetching table data:", error);
      }
    };
    fetchMontylEffort();
  }, [filterDate]);

  const handleUserChange = (event) => {
    const selectedUserId = event.target.value;

    const selectedUser = AllUsers.find(
      (user) => user.UserID === selectedUserId
    );

    setAdminSelectedUser(selectedUser);
  };

  const SelectedCheckValues = () => {
    const [selectedUpdateCheckValues, setSelectedUpdateCheckValues] = useState({
      discountsDone: false,
      legalCheckDone: false,
      caseControlDone: false,
      messagesAnswered: false,
      noUnshippedOrders: false,
      storeDataEntries: false,
    });
    const [checkTimes, setCheckTimes] = useState({});
    const isToday = isSameDay(new Date(today), new Date(filterDate));
    const isMonday = new Date(filterDate).getDay() === 1;

    useEffect(() => {
      const fetchChecks = async () => {
        try {
          const response = await axios.post(path + "/api/getUserChecks", {
            UserID: user?.ID,
            selectedDate: filterDate,
          });

          const fetchedData = response.data.reduce(
            (acc, item) => {
              acc.updateValues[item.CheckBoxType] = true; // Checkbox durumu
              acc.checkTimes[item.CheckBoxType] = new Date(
                item.CheckDate
              ).toLocaleTimeString("tr-TR"); // Check tarihi (saat kısmı)
              return acc;
            },
            { updateValues: {}, checkTimes: {} }
          );

          setSelectedUpdateCheckValues({
            discountsDone: fetchedData.updateValues.discountsDone || false,
            legalCheckDone: fetchedData.updateValues.legalCheckDone || false,
            caseControlDone: fetchedData.updateValues.caseControlDone || false,
            messagesAnswered:
              fetchedData.updateValues.messagesAnswered || false,
            noUnshippedOrders:
              fetchedData.updateValues.noUnshippedOrders || false,
            storeDataEntries:
              fetchedData.updateValues.storeDataEntries || false,
          });

          setCheckTimes(fetchedData.checkTimes);
        } catch (error) {
          console.error("Error fetching check data:", error);
        }
      };

      fetchChecks();
    }, [filterDate]);

    const handleUpdateCheckChange = async (event) => {
      if (!isToday) return;

      const { name } = event.target;

      const currentTime = new Date(
        new Date().toLocaleString("en-US", { timeZone: "Europe/Istanbul" })
      );

      console.log(currentTime);

      const newValues = {
        ...selectedUpdateCheckValues,
        [name]: true,
      };

      setSelectedUpdateCheckValues(newValues);
      setCheckTimes({
        ...checkTimes,
        [name]: currentTime.toLocaleTimeString("tr-TR"),
      });
      try {
        await axios.post(path + "/api/insertCheck", {
          UserID: user?.ID,
          UserName: user?.Username,
          CheckBoxType: name,
          CheckDate: currentTime,
        });
      } catch (error) {
        console.error("Error sending data to backend:", error);
      }
    };

    return (
      <FormControl component="fieldset" sx={{ margin: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            mt: 0,
          }}
        >
          <FormLabel component="legend" sx={{ fontWeight: "bold" }}>
            Kontrol Listesi
          </FormLabel>
        </Box>

        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
            mt: 0,
            marginLeft: 15,
          }}
        >
          <FormGroup>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0,
              }}
            >
              {[
                { name: "discountsDone", label: "İndirimler Günlük Yapıldı" },
                { name: "legalCheckDone", label: "Legal Kontrolü Yapıldı" },
                { name: "caseControlDone", label: "Case Kontrolü Yapıldı" },
                { name: "messagesAnswered", label: "Mesajlara Cevap Verildi" },
                {
                  name: "noUnshippedOrders",
                  label: "Gitmeyen Order Kontrolleri Yapıldı",
                },
                isMonday && {
                  name: "storeDataEntries",
                  label: "Mağaza Veri Girişleri Yapıldı",
                },
              ]
                .filter(Boolean)
                .map((item) => (
                  <Box
                    key={item.name}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "calc(50% - 8px)",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedUpdateCheckValues[item.name]}
                          onChange={handleUpdateCheckChange}
                          name={item.name}
                          disabled={
                            selectedUpdateCheckValues[item.name] || !isToday
                          }
                          sx={{
                            color: selectedUpdateCheckValues[item.name]
                              ? "green"
                              : "red",
                            "&.Mui-checked": {
                              color: "green",
                            },
                          }}
                        />
                      }
                      label={item.label}
                    />
                    {selectedUpdateCheckValues[item.name] &&
                      checkTimes[item.name] && (
                        <Typography
                          variant="caption"
                          sx={{ marginLeft: "8px", color: "gray" }}
                        >
                          {checkTimes[item.name]}
                        </Typography>
                      )}
                  </Box>
                ))}
            </Box>
            <Divider />
          </FormGroup>
        </Box>
      </FormControl>
    );
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchShopAuth = async () => {
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: user?.ID,
          UserIsAdmin: user?.ReportAdmin,
        });
  
        // Map ile objeleri oluştur
        const allOptions = response.data.data.map((shop) => ({
          value: shop.ShopName,
          label: shop.ShopName,
        }));
  
        // Tekilleştirme işlemi (Set kullanarak)
        const uniqueOptions = Array.from(
          new Map(allOptions.map((item) => [item.value, item])).values()
        );
  
        setOptions(uniqueOptions);
        console.log(uniqueOptions);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopAuth();
  }, [user, path]);
  

  const [selectedValues, setSelectedValues] = useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };
  const handleUpdateChange = (event) => {
    const {
      target: { value },
    } = event;

    const newShopValues = typeof value === "string" ? value.split(",") : value;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      SelelectedShop: newShopValues,
    }));
  };
  const [files, setFiles] = useState([]);

  const onUpload = (event) => {
    const uploadedFiles = event.files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    if (files.length + uploadedFiles.length > 3) {
      alert("En fazla 3 dosya yükleyebilirsiniz!");
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
  };

  const removeFile = (fileToRemove) => {
    setFiles((files) => files.filter((file) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const buttonClicked = event.nativeEvent.submitter.name;
    const data = new FormData(event.target);
    console.log(data.get("description"));
    try {
      const selectedShop = data.get("SelelectedShop");
      const UserID = AdminSelectedUser?.UserID ?? user?.ID;
      const username = AdminSelectedUser?.Username ?? user?.Username;
      const startDate = selectedDate.format("DD/MM/YYYY");
      const startTime = data.get("startTime");
      const endTime = data.get("endTime");
      const SelectedEfforts = data.get("effortType");
      const Piace = data.get("Piace");

      if (!startDate || !startTime || !endTime || !SelectedEfforts) {
        setAlertSeverity("error");
        setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
        setAlertOpen(true);
        return;
      }
      if (
        startTime.includes("HH:MM") ||
        endTime.includes("HH:MM") ||
        endTime.includes("HH") ||
        endTime.includes("MM") ||
        startTime.includes("HH") ||
        startTime.includes("MM")
      ) {
        setAlertSeverity("error");
        setAlertMessage("Lütfen Başlangıç ve Bitiş Saatlerini Doğru Seçiniz!");
        setAlertOpen(true);
        return;
      }
      if (!UserID || !username) {
        setAlertSeverity("error");
        setAlertMessage(
          "Beklenmedik Bir Hata Oluştu Lütfen Yöneticinizle İletişime Geçiniz!"
        );
        setAlertOpen(true);
        return;
      }

      const base64Files = await Promise.all(
        files.map((file) => toBase64(file))
      );
      const TimeSummary = `${Math.floor(calculateTimeDifference() / 60)}:${
        calculateTimeDifference() % 60
      }`;

      const response = await axios.post(path + "/api/SaveFormData", {
        UserID,
        username,
        selectedShop,
        startDate,
        startTime,
        endTime,
        SelectedEfforts,
        description: data.get("description"),
        storeLinks: data.get("storeLinks"),
        sellerLinks: data.get("sellerLinks"),
        Image1: base64Files[0] || null,
        Image2: base64Files[1] || null,
        Image3: base64Files[2] || null,
        TimeSummary,
        Piace,
      });
      setAlertSeverity("success");
      setAlertMessage("Efor başarıyla kaydedildi.");
      setAlertOpen(true);

      setTimeout(() => {
        event.target.reset();
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        setAlertSeverity("error");
        setAlertMessage(
          error.response.data.message || "Efor kaydedilirken bir hata oluştu."
        );
      } else {
        setAlertSeverity("error");
        setAlertMessage("Efor kaydedilirken bir hata oluştu: " + error.message);
      }

      setAlertOpen(true);
    }
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();

    const buttonClicked = event.nativeEvent.submitter.name;

    const data = new FormData(event.target);

    try {
      const base64Files = await Promise.all(
        files.map((file) => toBase64(file))
      );
      const date = dayjs.tz(formValues.startDate, "Europe/Istanbul").format("DD/MM/YYYY");
      console.log(date);
      const indexID = data.get("FormID");
      const selectedShop = data.get("SelelectedShop");
      const UserID = formValues.UserID;
      const username = formValues.UserName;
      const startDate = date;
      const startTime = data.get("startTime");
      const endTime = data.get("endTime");
      const description = data.get("description");
      const storeLinks = data.get("storeLinks");
      const sellerLinks = data.get("sellerLinks");
      const SelectedEfforts = data.get("effortType");
      const Piace = data.get("Piace");
      const TimeSummary = `${Math.floor(
        calculateTimeDifferenceUpdate() / 60
      )}:${calculateTimeDifferenceUpdate() % 60}`;

      if (!startDate || !startTime || !endTime) {
        setAlertSeverity("error");
        setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
        setAlertOpen(true);
        return;
      }

      if (!UserID || !username) {
        setAlertSeverity("error");
        setAlertMessage(
          "Beklenmedik Bir Hata Oluştu. Lütfen Yöneticinizle İletişime Geçiniz!"
        );
        setAlertOpen(true);
        return;
      }

      const response = await axios.post(`${path}/api/UpdateFormData`, {
        indexID,
        UserID,
        username,
        selectedShop,
        startDate,
        startTime,
        endTime,
        SelectedEfforts,
        description,
        storeLinks,
        sellerLinks,
        TimeSummary,
        Piace,
      });

      setAlertSeverity("success");
      setAlertMessage("Efor başarıyla güncellendi.");
      setAlertOpen(true);

      setTimeout(() => {
        event.target.reset();
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        setAlertSeverity("error");
        setAlertMessage(
          error.response.data.message || "Efor kaydedilirken bir hata oluştu."
        );
      } else {
        setAlertSeverity("error");
        setAlertMessage("Efor kaydedilirken bir hata oluştu: " + error.message);
      }

      setAlertOpen(true);
    }
  };
  const [selectedRow, setSelectedRow] = useState(null); // Seçili satırın bilgisi
  const [formValues, setFormValues] = useState({
    UserID : "",
    UserName: "",
    SelelectedShop: [],
    startDate: null,
    startTime: null,
    endTime: null,
    description: "",
    storeLinks: "",
    sellerLinks: "",
    indexID: null,
    effortType: [],
    discountsDoneUpt: false,
    legalCheckDoneUpt: false,
    caseControlDoneUpt: false,
    messagesAnsweredUpt: false,
    noUnshippedOrdersUpt: false,
    Piace: 0,
  });
  const [mainFormValues, setMainFormValues] = useState({
    description: "",
    storeLinks: "",
    sellerLinks: "",
    Piace: 0,
  });

  const formatTimeSummary = (timeSummary) => {
    const [hours, minutes] = timeSummary.split(":");
    return `${hours} saat ${minutes} dakika`;
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
    }
  };

  const calculateTotalEffort = () => {
    let totalMinutes = 0;
    console.log(tableData);
    tableData.forEach((row) => {
      // TimeSummary formatı "X saat Y dakika" gibi
      const timeParts = row.TimeSummary.split(":"); // ["X", "saat", "Y", "dakika"]
      const hours = parseInt(timeParts[0]) || 0; // Saat kısmını al
      const minutes = parseInt(timeParts[1]) || 0; // Dakika kısmını al

      totalMinutes += hours * 60 + minutes; // Toplam dakikaları hesapla
    });

    return totalMinutes;
  };

  // Toplam süreyi saat ve dakika olarak formatlama
  const formatTotalEffort = () => {
    const totalMinutes = calculateTotalEffort();
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours} saat ${minutes} dakika`;
  };
  const formatTotalEffortMonthly = () => {
    // monthlyEffort boş array ise veya veri henüz gelmediyse
    if (!monthlyEffort || monthlyEffort.length === 0) {
      return "0 saat 0 dakika";
    }

    // Array'in ilk elemanının TotalEffort değerini al
    const [hours, minutes] = monthlyEffort[0].TotalEffort.split(":");
    return `${hours} saat ${minutes} dakika`;
  };

  const UploadFoler = () => {
    return (
      <div className="card" style={{ background: "#405D72" }}>
        <FileUpload
          style={{ background: "#405D72" }}
          name="demo[]"
          customUpload
          multiple
          maxFileSize={1000000}
          accept="image/*"
          onSelect={onUpload}
          chooseLabel="Resim Seç"
          Upload={false}
          showCancelButton={false}
          emptyTemplate={
            <div>
              {files.length > 0 ? (
                <table className="p-datatable p-datatable-gridlines">
                  <tbody>
                    <tr>
                      {files.map((file, index) => (
                        <td key={index}>
                          <div style={{ textAlign: "center" }}>
                            <img
                              alt={file.name}
                              src={file.preview}
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                display: "block",
                                marginBottom: "8px",
                              }}
                            />
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<DeleteIcon />}
                              onClick={() => removeFile(file)}
                              style={{ marginTop: "1px" }}
                            ></Button>
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className="p-m-0">Sürükleyin ve bırakın ya da seçin.</p>
              )}
            </div>
          }
        />
      </div>
    );
  };
  const [searchTerm, setSearchTerm] = useState("");

  // Arama fonksiyonu
  const filteredData = tableData.filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#405D72",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "90vw",
            height: "98vh",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            backgroundColor: "rgb(159, 176, 176)",
            borderRadius: "25px",
            overflow: "hidden", // İçerik taşmalarını engelle
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2, // padding
                bgcolor: "rgb(200, 210, 210)",
                overflowY: "auto",
              }}
            >
              <Typography variant="h5" textAlign="center">
                Girilmiş Eforlar
              </Typography>
              <Box sx={{ marginTop: 3 }}>
                <DatePicker
                  label="Tarih Seçin"
                  name="startDate"
                  id="user-effort-date"
                  value={filterDate}
                  inputFormat="MM/DD/YYYY"
                  onChange={(newValue) => setFilterDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  mt: -1,
                }}
              >
                <SelectedCheckValues />
              </Box>
              <IconButton
                onClick={() => navigate("/")}
                sx={{
                  position: "absolute", // Mutlak konumlandırma
                  top: 20, // Üstten 10px boşluk
                  left: 150, // Soldan 10px boşluk
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: "5px 15px",
                  borderRadius: "25px",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#ccc",
                  },
                }}
              >
                <Avatar
                  src="/Logos/HORSE HEAD.svg"
                  alt="Ana Menü"
                  sx={{ width: 50, height: 50 }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Ana Menü
                </Typography>
              </IconButton>
              <Box>
              <Box  sx={{
                flex: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
              }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Ara..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{
                    maxWidth: "80%",
                    mb: 0,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "white",
                      "&:hover": {
                        "& > fieldset": {
                          borderColor: "#405D72",
                        },
                      },
                      "&.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#405D72",
                        },
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#405D72" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                </Box>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxWidth: "80%",
                    maxHeight: "80vh",
                    margin: "auto",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "8px",
                    marginTop: 1,
                    maxHeight: "40vh",
                    overflow: "auto",
                  }}
                >
                  <Table
                    sx={{
                      width: "100%",
                      "& th": {
                        backgroundColor: "#405D72",
                        color: "white",
                        fontWeight: "bold",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      },
                      "& td": {
                        padding: "12px",
                      },
                      "& tr:nth-of-type(even)": {
                        backgroundColor: "#f5f5f5",
                      },
                      "& tr:hover": {
                        backgroundColor: "#e0f7fa",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <strong>Efor Güncelle</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Kullanıcı Adı</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Mağaza</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Efor Tarihi</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Başlangıç Saati</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Bitiş Saati</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Toplam Süre</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Yapılan İş</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData.map((row) => {
                        const isToday = isSameDay(
                          new Date(row.EffortDate),
                          today
                        );

                        return (
                          <TableRow key={row.Id}>
                            <TableCell>
                              <IconButton
                                color="primary"
                                disabled={!isToday && user?.ReportAdmin !== 1}
                                onClick={() => {
                                  setSelectedRow(row);
                                 console.log(row.EffortDate);
                                  setFormValues({
                                    SelelectedShop: row.Shop
                                      ? row.Shop.split(",").map((item) =>
                                          item.trim()
                                        )
                                      : [],
                                      
                                    startDate: dayjs(row.EffortDate), // Convert to dayjs
                                    startTime: dayjs(row.StartHour, "HH:mm"), // Use 24h format
                                    endTime: dayjs(row.EndHour, "HH:mm"),
                                    description: row.Explanation || "",
                                    storeLinks: row.ShopLinks || "",
                                    sellerLinks: row.PurchLinks || "",
                                    indexID: row.Id,
                                    effortType: row.SelectedEfforts
                                      ? row.SelectedEfforts.split(",").map(
                                          (item) => item.trim()
                                        )
                                      : [],
                                    UserID: row.UserID,
                                    UserName: row.UserName,
                                  });

                                  setValue(1);
                                }}
                              >
                                <EditNoteIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell>{row.UserName}</TableCell>
                            <TableCell>{row.Shop}</TableCell>
                            <TableCell>
                              {new Date(row.EffortDate).toLocaleDateString(
                                "tr-TR"
                              )}
                            </TableCell>
                            <TableCell>{row.StartHour}</TableCell>
                            <TableCell>{row.EndHour}</TableCell>
                            <TableCell>
                              {formatTimeSummary(row.TimeSummary)}
                            </TableCell>
                            <TableCell>{row.SelectedEfforts}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row", // Yan yana dizilim
                  gap: 2, // İki Box arasında boşluk
                  justifyContent: "center", // Ortada hizalama
                  alignItems: "center", // Dikey ortalama
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    backgroundColor: "#f0f0f0",
                    borderRadius: 2,
                    bgcolor: "rgb(180, 190, 190)",
                    marginTop: 2,
                  }}
                >
                  <Typography variant="h6">Günün Toplam Eforu</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {tableData.length > 0
                      ? formatTotalEffort()
                      : "Lütfen efor girdiğiniz bir gün seçin."}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 2,
                    backgroundColor: "#f0f0f0",
                    borderRadius: 2,
                    bgcolor: "rgb(180, 190, 190)",
                    marginTop: 2,
                  }}
                >
                  <Typography variant="h6">Ayın Toplam Eforu</Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {monthlyEffort.length > 0
                      ? formatTotalEffortMonthly()
                      : "Lütfen efor girdiğiniz bir ay seçin."}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </LocalizationProvider>

          {/* Sağ Panel */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // Artık sabit yükseklik kullanmıyoruz.
              // height: "100vh",
              minHeight: "100vh", // sayfa boyu kadar arkaplanın uzaması için
              bgcolor: "rgb(180, 190, 190)",
              p: 2,
            }}
          >
            <Box
              sx={{
                overflowY: "auto",

                mx: "auto",

                maxWidth: 1200,
                width: "100%",
                p: 2,
                boxSizing: "border-box",
                "&::-webkit-scrollbar": {
                  // Customize scrollbar
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Dikey ortalama iptal edildi
                  alignItems: "center", // Yatay ortalama iptal edildi
                }}
              >
                <Box
                  sx={{
                    borderColor: "divider",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChanges}
                    aria-label="basic tabs example"
                    sx={{
                      minHeight: 30, // Tabların yüksekliğini küçültür
                    }}
                  >
                    <Tab
                      label="Efor Tanımlama"
                      {...a11yProps(0)}
                      sx={{
                        minWidth: 80, // Minimum genişlik
                        padding: "6px 12px", // İç dolgu
                        fontSize: "0.800rem", // Yazı boyutu
                        lineHeight: "1", // Satır yüksekliği
                      }}
                    />
                    <Tab
                      label="Efor Güncelleme"
                      {...a11yProps(1)}
                      sx={{
                        minWidth: 80,
                        padding: "6px 12px",
                        fontSize: "0.800rem",
                        lineHeight: "1",
                      }}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <form onSubmit={handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        component={Paper}
                        elevation={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 6,
                          maxWidth: 600,
                          margin: "auto",
                          bgcolor: "rgb(200, 210, 210)",
                        }}
                      >
                        <Typography variant="h5" textAlign="center">
                          Efor Tanımlama Ekranı
                        </Typography>
                        {user?.ReportAdmin === 1 && (
                          <FormControl fullWidth>
                            <InputLabel>Kullanıcı Seç</InputLabel>
                            <Select
                              label="Kullanıcı Seç"
                              value={AdminSelectedUser?.UserID || ""}
                              onChange={handleUserChange}
                            >
                              {AllUsers.map((user) => (
                                <MenuItem key={user.UserID} value={user.UserID}>
                                  {user.Username}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <FormControl
                            sx={{ minWidth: "48.5%" }}
                            variant="outlined"
                          >
                            <InputLabel id="dynamic-dropdown-label">
                              Shop
                            </InputLabel>
                            <Select
                              labelId="dynamic-dropdown-label"
                              name="SelelectedShop"
                              value={selectedValues}
                              onChange={handleChange}
                              renderValue={(selected) => {
                                if (!Array.isArray(selected)) {
                                  return "";
                                }
                                return selected
                                  .map(
                                    (val) =>
                                      options.find(
                                        (option) => option.value === val
                                      )?.label
                                  )
                                  .filter(Boolean)
                                  .join(", ");
                              }}
                              label="Shop"
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  <ListItemText primary={option.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <DatePicker
                            label="Tarih Seçin"
                            name="startDate"
                            id="user-effort-date"
                            value={selectedDate}
                            inputFormat="MM/DD/YYYY" // Tarih formatı
                            onChange={(newValue) => setSelectedDate(newValue)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            disabled={!(user?.ReportAdmin === 1)}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <TimePicker
                            label="Başlangıç Saati"
                            name="startTime"
                            value={startTime}
                            onChange={(newTime) => setStartTime(newTime)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            ampm={false}
                            maxTime={
                              user?.ReportAdmin === 1 ? undefined : nowInIstanbul
                            }
                          />
                          <TimePicker
                            label="Bitiş Saati"
                            name="endTime"
                            value={endTime}
                            onChange={(newTime) => setEndTime(newTime)}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            ampm={false}
                            closeOnSelect={false}
                            maxTime={
                              user?.ReportAdmin === 1 ? undefined : nowInIstanbul
                            }
                          />
                        </Box>

                        <Box>
                          <FormControl
                            sx={{ width: "100%", marginBottom: 1 }}
                            variant="outlined"
                          >
                            <InputLabel id="effort-select-label">
                              Efor Türü
                            </InputLabel>
                            <Select
                              labelId="effort-select-label"
                              multiple
                              name="effortType"
                              value={selectedEffortValue}
                              onChange={handleEffortChange}
                              renderValue={(selected) => {
                                // Ensure selected is always an array
                                if (!Array.isArray(selected)) {
                                  return "";
                                }
                                return selected
                                  .filter((value) =>
                                    effortOptions.includes(value)
                                  )
                                  .join(", ");
                              }}
                              label="Efor Türü"
                            >
                              {effortOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  <Checkbox
                                    checked={
                                      selectedEffortValue.indexOf(option) > -1
                                    }
                                  />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <Box sx={{ display: "flex", marginBottom: 1 }}>
                            {isPiaceVisible && (
                              <TextField
                                defaultValue={mainFormValues.Piace || ""}
                                style={{ width: "100%" }}
                                id="user-effort-purchese-links"
                                label="Adet"
                                name="Piace"
                                type="number"
                                inputProps={{
                                  min: "0",
                                  step: "1",
                                  pattern: "[0-9]*",
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "." || e.key === ",") {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                  e.target.value = value;
                                }}
                                onBlur={(e) => {
                                  const value = e.target.value;
                                  setMainFormValues((prev) => ({
                                    ...prev,
                                    Piace: value,
                                  }));
                                }}
                              />
                            )}
                          </Box>

                          {/* TextField bileşeni */}
                          {isVisible && (
                            <TextField
                              style={{ width: "100%", marginBottom: 0 }}
                              id="user-effort-description"
                              label="Efor Açıklaması"
                              name="description"
                              defaultValue={mainFormValues.description || ""} // Değer başlangıçta gösterilir
                              onBlur={(e) => {
                                const value = e.target.value;
                                setMainFormValues((prev) => ({
                                  ...prev,
                                  description: value,
                                }));
                              }}
                              multiline
                              rows={3}
                            />
                          )}
                        </Box>
                        {isFolderVisible && (
                          <Box>
                            <TextField
                              style={{ width: "100%" }}
                              defaultValue={mainFormValues.storeLinks || ""}
                              id="user-effort-links"
                              label="Mağaza Linkleri"
                              name="storeLinks"
                              multiline
                              rows={2}
                              onBlur={(e) => {
                                const value = e.target.value;
                                setMainFormValues((prev) => ({
                                  ...prev,
                                  storeLinks: value,
                                }));
                              }}
                            />
                            <TextField
                              style={{ width: "100%", marginTop: 10 }} // Araya boşluk eklemek için marginTop
                              id="user-effort-purchese"
                              defaultValue={mainFormValues.sellerLinks || ""}
                              label="Satıcı Linkleri"
                              name="sellerLinks"
                              multiline
                              rows={2}
                              onBlur={(e) => {
                                const value = e.target.value;
                                setMainFormValues((prev) => ({
                                  ...prev,
                                  sellerLinks: value,
                                }));
                              }}
                            />
                          </Box>
                        )}
                        {isFolderVisible && <UploadFoler />}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 2,
                            backgroundColor: "#f0f0f0",
                            borderRadius: 2,
                            bgcolor: "#99BC85",
                          }}
                        >
                          <Typography variant="h6">
                            Gerçekleştirilen Efor
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {calculateTimeDifference() !== null
                              ? `${Math.floor(
                                  calculateTimeDifference() / 60
                                )} saat ${
                                  calculateTimeDifference() % 60
                                } dakika`
                              : "Lütfen başlangıç ve bitiş saatini seçin."}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                          >
                            Eforu Kaydet
                          </Button>
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </form>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <form onSubmit={handleUpdateSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        component={Paper}
                        elevation={4}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          padding: 6,
                          maxWidth: 600,
                          margin: "auto",
                          bgcolor: "rgb(200, 210, 210)",
                        }}
                      >
                        <Typography variant="h5" textAlign="center">
                          Efor Güncelleme Ekranı
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <FormControl
                            sx={{ minWidth: "48.5%" }}
                            variant="outlined"
                          >
                            <InputLabel id="dynamic-dropdown-label">
                              Shop
                            </InputLabel>
                            <Select
                              labelId="dynamic-dropdown-label"
                              name="SelelectedShop"
                              value={formValues.SelelectedShop}
                              onChange={handleUpdateChange}
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (val) =>
                                      options.find(
                                        (option) => option.value === val
                                      )?.label
                                  )
                                  .join(", ")
                              }
                              label="Shop"
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  <ListItemText primary={option.label} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <DatePicker
                            label="Tarih Seçin"
                            name="startDate"
                            value={formValues.startDate}
                            onChange={(newValue) =>
                              setFormValues({
                                ...formValues,
                                startDate: newValue,
                              })
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            disabled={!(user?.ReportAdmin === 1)}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 2,
                          }}
                        >
                          <TimePicker
                            label="Başlangıç Saati"
                            name="startTime"
                            value={formValues.startTime}
                            onChange={(newTime) =>
                              setFormValues({
                                ...formValues,
                                startTime: newTime,
                              })
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            ampm={false}
                            maxTime={
                              user?.ReportAdmin === 1 ? undefined : nowInIstanbul
                            }
                          />

                          <TimePicker
                            label="Bitiş Saati"
                            name="endTime"
                            value={formValues.endTime}
                            onChange={(newTime) =>
                              setFormValues({
                                ...formValues,
                                endTime: newTime,
                              })
                            }
                            renderInput={(params) => (
                              <TextField {...params} fullWidth />
                            )}
                            ampm={false}
                            maxTime={
                              user?.ReportAdmin === 1 ? undefined : nowInIstanbul
                            }
                          />
                        </Box>

                        <Box sx={{ width: "100%" }}>
                          <Box>
                            <FormControl
                              sx={{ width: "100%", marginBottom: 1 }}
                              variant="outlined"
                            >
                              <InputLabel id="effort-select-label">
                                Efor Türü
                              </InputLabel>
                              <Select
                                labelId="effort-select-label"
                                multiple
                                name="effortType"
                                value={formValues.effortType}
                                onChange={handleUpdateEffortChange}
                                renderValue={(selected) => selected.join(", ")}
                                label="Efor Türü"
                              >
                                {effortOptions.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    <Checkbox
                                      checked={
                                        selectedEffortValue.indexOf(option) > -1
                                      }
                                    />
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              label="Efor Açıklaması"
                              name="description"
                              multiline
                              rows={3}
                              defaultValue={formValues.description || ""} // Değer başlangıçta gösterilir
                              onBlur={(e) => {
                                const value = e.target.value;
                                setFormValues((prev) => ({
                                  ...prev,
                                  description: value,
                                }));
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Box>
                          <TextField
                            value={formValues.indexID}
                            type="hidden"
                            name="FormID"
                            sx={{ display: "none" }} // Preferred Material-UI way
                          />
                        </Box>
                        <Box>
                          <TextField
                            defaultValue={formValues.storeLinks || ""} // Başlangıç değeri ayarlanır
                            style={{ width: "100%" }}
                            id="user-effort-links"
                            label="Mağaza Linkleri"
                            name="storeLinks"
                            multiline
                            rows={2}
                            onBlur={(e) => {
                              const value = e.target.value;
                              setFormValues((prev) => ({
                                ...prev,
                                storeLinks: value,
                              }));
                            }}
                          />
                        </Box>
                        <Box>
                          <TextField
                            defaultValue={formValues.sellerLinks || ""}
                            style={{ width: "100%" }}
                            id="user-effort-purchese-links"
                            label="Satıcı Linkleri"
                            name="sellerLinks"
                            multiline
                            rows={2}
                            onBlur={(e) => {
                              const value = e.target.value;
                              setFormValues((prev) => ({
                                ...prev,
                                sellerLinks: value,
                              }));
                            }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            defaultValue={formValues.Piace || ""}
                            style={{ width: "100%" }}
                            id="user-effort-purchese-links"
                            label="Adet"
                            name="Piace"
                            type="number"
                            inputProps={{
                              min: "0",
                              step: "1",
                              pattern: "[0-9]*",
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "." || e.key === ",") {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              e.target.value = value;
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;
                              setFormValues((prev) => ({
                                ...prev,
                                Piace: value,
                              }));
                            }}
                          />
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 2,
                            backgroundColor: "#f0f0f0",
                            borderRadius: 2,
                            bgcolor: "#99BC85",
                          }}
                        >
                          <Typography variant="h6">Güncellenen Efor</Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold" }}
                          >
                            {calculateTimeDifferenceUpdate() !== null
                              ? `${Math.floor(
                                  calculateTimeDifferenceUpdate() / 60
                                )} saat ${
                                  calculateTimeDifferenceUpdate() % 60
                                } dakika`
                              : "Lütfen başlangıç ve bitiş saatini seçin."}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                          >
                            Efor Güncelle
                          </Button>
                        </Box>
                      </Box>
                    </LocalizationProvider>
                  </form>
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserEffort;
