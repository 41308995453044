import React, { useEffect, useState, useMemo } from "react";
import "./CSS/MaintenanceScreen.css";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import axios from "axios";
import Button from "@mui/material/Button";
import { useUser } from "./Context/UserContext";
import { Alert, Snackbar } from "@mui/material";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Container,
  Chip,
  TablePagination,
  Stack,
  ButtonGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import dayjs from "dayjs";

import InfoIcon from "@mui/icons-material/Info";
import { Search } from "lucide-react";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import "./CSS/UserReport.css";
import { Image as ImageIcon } from "lucide-react";
import { saveAs } from "file-saver";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Pagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import utc from "dayjs/plugin/utc";
import { use } from "react";
import PeopleIcon from "@mui/icons-material/People";
import StoreIcon from "@mui/icons-material/Store";
import BarChartIcon from "@mui/icons-material/BarChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VisibilityIcon from "@mui/icons-material/Visibility";

dayjs.extend(utc);

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        },
      },
    ],
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const UserReports = () => {
  let status = "1";
  let path =
    status === "0" ? "http://localhost:3003" : "https://outlettee.com:3003";

  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [userInfos, setUserInfos] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { user } = useUser();
  console.log(user);
  const COLORS = [
    "#FA8072", // Somon
    "#FF5733", // Turuncu-kırmızı
    "#FFBD33", // Altın sarısı
    "#75FF33", // Açık yeşil
    "#33FF57", // Yeşil ton
    "#33FFBD", // Turkuaz ton
    "#33DBFF", // Açık mavi
    "#3375FF", // Mavi
    "#5733FF", // Mor ton
    "#BD33FF", // Eflatun
    "#FF33DB", // Pembe ton
    "#FF3375", // Pembe-kırmızı
    "#FF3333", // Kırmızı
    "#FF6F61", // Mercan
    "#FFD700", // Altın
    "#ADFF2F", // Yeşil-sarı
    "#7FFF00", // Chartreuse
    "#00FA9A", // Orman yeşili
    "#00CED1", // Koyu Turkuaz
    "#1E90FF", // Dodger Mavisi
    "#4169E1", // Kraliyet Mavisi
    "#8A2BE2", // Mavi-mor
    "#9400D3", // Koyu mor
    "#C71585", // Orkide
    "#FF1493", // Pembe
    "#FF7F50", // Mercan
    "#CD5C5C", // Koyu mercan
    "#F08080", // Açık mercan
    "#DBFF33", // Sarı-yeşil
    "#FF00FF", // Fuşya
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenuClick = (menu) => {
    setSelectedMenu(menu); // Menü tıklamalarını yakala
  };

  const [selectedUser, setSelectedUser] = useState("all");
  const [selectedShop, setSelectedShop] = useState("all");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedShopData, setSelectedShopData] = useState("all");
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [shopExpensesData, setShopExpensesData] = useState([]);
  const [userEffortsFees, setUserEffortsFees] = useState([]);

  const fetchUserFee = async () => {
    try {
      const response = await axios.get(path + "/api/AllEffortsFees");
      const parsedData = response.data;
      console.log(response.data);
      if (parsedData && parsedData.length > 0) {
        const updatedEfforts = userData.map((user) => {
          const existingEffort = parsedData.find(
            (effort) => effort.userId === user.UserID
          );

          return {
            userId: user.UserID,
            userName: user.UserName,
            effortType1: existingEffort?.effortType1 || "",
            effortType1Fee: existingEffort?.effortType1Fee || "",
            effortType2: existingEffort?.effortType2 || "",
            effortType2Fee: existingEffort?.effortType2Fee || "",
          };
        });
        setUserEffortsFees(updatedEfforts);
      }
    } catch (error) {
      console.error("Error fetching shop data:", error);
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFetchData = async () => {
    setLoading(true);
    setError("");
    setUserData([]);
    try {
      // startDate ve endDate'i dayjs nesnesine çevirerek formatla
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      console.log("Start Date:", formattedStartDate);
      console.log("End Date:", formattedEndDate);

      const response = await axios.post(
        `${path}/api/GetUserEffortViaParamaters`,
        {
          UserID: user?.ID,
          isAdmin: user?.Admin,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }
      );

      setUserData(response.data);
    } catch (error) {
      setError("Veriler alınırken hata oluştu.");
      console.error("Error fetching shop data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserFee();

    const fetchShopExpenses = async () => {
      try {
        setShopExpensesData([]);
        const response = await axios.get(path + "/api/GetAllShopExpenses");
        const parsedData = response.data;
        console.log(parsedData);
        setShopExpensesData(parsedData);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopExpenses();

    const fetchShopAuth = async () => {
      try {
        const response = await axios.post(path + "/api/getUserShops", {
          UserID: user?.ID,
        });
        const options = response.data.data.map((shop) => ({
          value: shop.ShopName,
          label: shop.ShopName,
        }));
        setOptions(options);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShopAuth();

    const fetchShop = async () => {
      try {
        setData([]);
        const response = await axios.post(path + "/api/GetAllShopData", {
          UserID: user?.ID,
          isAdmin: user?.Admin,
        });
        const parsedData = response.data;
        console.log(parsedData);
        setData(parsedData);
      } catch (error) {
        console.error("Error fetching shop data:", error);
      }
    };
    fetchShop();
  }, []);

  useEffect(() => {
    fetchUserFee();
  }, [userData]);

  const [options, setOptions] = useState([]);

  // Benzersiz kullanıcı ve mağaza listelerini oluştur
  const users = [...new Set(userData.map((item) => item.UserName))];
  const shops = [...new Set(userData.map((item) => item.Shop))];

  const filteredData = useMemo(() => {
    return userData.filter((item) => {
      const userMatch =
        selectedUser === "all" || item.UserName === selectedUser;
      const shopMatch = selectedShop === "all" || item.Shop === selectedShop;

      const itemDate = new Date(item.EffortDate);
      itemDate.setHours(0, 0, 0, 0);

      let dateMatch = true;

      if (startDate) {
        const startDateObj = new Date(startDate);
        startDateObj.setHours(0, 0, 0, 0);
        dateMatch = dateMatch && itemDate >= startDateObj;
      }

      if (endDate) {
        const endDateObj = new Date(endDate);
        endDateObj.setHours(0, 0, 0, 0);
        dateMatch = dateMatch && itemDate <= endDateObj;
      }

      return userMatch && shopMatch && dateMatch;
    });
  }, [selectedUser, selectedShop, userData, startDate, endDate]);

  const convertTimeSummaryToHours = (timeSummary) => {
    const [hours, minutes] = timeSummary.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;
    const exactHours = totalMinutes / 60; // Yuvarlama YOK
    return exactHours;
  };

  const totalCalculations = useMemo(() => {
    const calculations = filteredData.reduce(
      (total, item) => {
        const hours = convertTimeSummaryToHours(item.TimeSummary);

        // Kullanıcının fee bilgilerini bul
        const userFees = userEffortsFees.find(
          (fee) => fee.userId === item.UserID
        );

        if (!userFees) return total;

        // Shop'a göre fee seçimi ve hesaplama
        if (item.Shop === "Arge") {
          if (user.Admin === 1 || user.Username === selectedUser) {
            const hourlyFee = parseFloat(userFees.effortType2Fee);
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + hours * hourlyFee,
              hours: total.hours + hours,
              cost: total.cost + hours * hourlyFee,
            };
          } else {
            return {
              ...total,
              argeHours: total.argeHours + hours,
              argeCost: total.argeCost + 0, // ARGE maliyeti sıfır
              hours: total.hours + hours,
              cost: total.cost + 0, // ARGE için maliyet eklenmez
            };
          }
        } else {
          const hourlyFee = parseFloat(userFees.effortType1Fee);
          return {
            ...total,
            otherHours: total.otherHours + hours,
            otherCost: total.otherCost + hours * hourlyFee,
            hours: total.hours + hours,
            cost: total.cost + hours * hourlyFee,
          };
        }
      },
      {
        hours: 0,
        cost: 0,
        argeHours: 0,
        argeCost: 0,
        otherHours: 0,
        otherCost: 0,
      }
    );

    return {
      totalWorkHours: calculations.hours.toFixed(2),
      totalCost: calculations.cost.toFixed(2),
      argeWorkHours: calculations.argeHours.toFixed(2),
      argeCost: calculations.argeCost.toFixed(2),
      otherWorkHours: calculations.otherHours.toFixed(2),
      otherCost: calculations.otherCost.toFixed(2),
    };
  }, [filteredData, userEffortsFees, user, selectedUser]);

  const shopCalculations = useMemo(() => {
    const calculations = {};

    filteredData.forEach((item) => {
      if (item.Shop === "") return;

      const hours = convertTimeSummaryToHours(item.TimeSummary);
      const userFees = userEffortsFees.find(
        (fee) => fee.userId === item.UserID
      );

      if (!userFees) return;

      const hourlyFee =
        item.Shop === "ARGE"
          ? parseFloat(userFees.effortType2Fee)
          : parseFloat(userFees.effortType1Fee);

      if (!calculations[item.Shop]) {
        calculations[item.Shop] = { hours: 0, cost: 0 };
      }

      calculations[item.Shop].hours += hours;
      calculations[item.Shop].cost += hours * hourlyFee;
    });

    return Object.entries(calculations).map(([shop, calc]) => ({
      shop,
      hours: Number(calc.hours.toFixed(2)),
      cost: Number(calc.cost.toFixed(2)),
    }));
  }, [filteredData, userEffortsFees]);

  const totalWorkHours = useMemo(() => {
    return filteredData
      .reduce((total, item) => {
        return total + convertTimeSummaryToHours(item.TimeSummary);
      }, 0)
      .toFixed(2);
  }, [filteredData]);

  const shopHours = useMemo(() => {
    const hours = {};
    filteredData.forEach((item) => {
      if (item.Shop === "") {
        return;
      }
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      hours[item.Shop] = (hours[item.Shop] || 0) + timeSpent;
    });

    return Object.entries(hours).map(([shop, hours]) => ({
      shop,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const dailyEfforts = useMemo(() => {
    const effortsByDate = {};
    filteredData.forEach((item) => {
      const date = new Date(item.EffortDate).toLocaleDateString();
      const timeSpent = convertTimeSummaryToHours(item.TimeSummary);
      effortsByDate[date] = (effortsByDate[date] || 0) + timeSpent;
    });
    return Object.entries(effortsByDate).map(([date, hours]) => ({
      date,
      hours: Number(hours.toFixed(2)),
    }));
  }, [filteredData]);

  const taskDistribution = useMemo(() => {
    const tasks = {};
    filteredData.forEach((item) => {
      const taskList = item.SelectedEfforts.split(",");
      const timePerTask =
        convertTimeSummaryToHours(item.TimeSummary) / taskList.length;
      taskList.forEach((task) => {
        tasks[task.trim()] = (tasks[task.trim()] || 0) + timePerTask;
      });
    });
    return Object.entries(tasks).map(([name, value]) => ({
      name,
      value: Number(value.toFixed(2)),
    }));
  }, [filteredData]);

  const UserDetailReports = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h4" component="h1">
              Efor Raporu
            </Typography>
          </Box>

          <Card sx={{ width: "100%", mb: 3, p: 2 }}>
            <CardContent
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                {/* Kullanıcı Seçimi */}
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Kullanıcı</InputLabel>
                  <Select
                    value={selectedUser}
                    label="Kullanıcı"
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user} value={user}>
                        {user}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Mağaza Seçimi */}
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Mağaza</InputLabel>
                  <Select
                    value={selectedShop}
                    label="Mağaza"
                    onChange={(e) => setSelectedShop(e.target.value)}
                  >
                    <MenuItem value="all">Tüm Mağazalar</MenuItem>
                    {shops.map((shop) => (
                      <MenuItem key={shop} value={shop}>
                        {shop}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Başlangıç Tarihi */}
                <DatePicker
                  label="Başlangıç Tarihi"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ minWidth: 180 }} />
                  )}
                />

                {/* Bitiş Tarihi */}
                <DatePicker
                  label="Bitiş Tarihi"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ minWidth: 180 }} />
                  )}
                  minDate={startDate}
                />

                {/* Verileri Getir Butonu */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFetchData}
                  disabled={loading}
                  sx={{ minWidth: 180, height: 56 }}
                >
                  {loading ? "Yükleniyor..." : "Verileri Getir"}
                </Button>
                {userData.length === 0 ? (
                  <Typography variant="h6" color="error">
                    Lütfen Başlangıç ve Bitiş Tarihlerini Seçiniz ve Verileri
                    Getir Butonuna Basınız
                  </Typography>
                ) : (
                  <Typography variant="h6" color="success">
                    Farklı Tarihte Veri Seçimi Yapabilirsiniz
                  </Typography>
                )}
              </Box>

              {/* Hata Mesajı */}
              {error && (
                <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                  {error}
                </Typography>
              )}
            </CardContent>
          </Card>

          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" color="primary">
                Toplam Çalışma Süresi: {totalWorkHours} saat
              </Typography>

              {(user.Admin === 1 || user.Username === selectedUser) && (
                <Typography variant="h6" color="secondary">
                  Toplam Maliyet: {totalCalculations.totalCost} USD
                  <Typography
                    component="span"
                    sx={{
                      color: "warning.main", // sarı renk
                      fontSize: "0.8em", // ana yazıdan daha küçük
                      marginLeft: 1, // sol boşluk
                    }}
                  >
                    (ARGE: {totalCalculations.argeCost} USD, Diğer:{" "}
                    {totalCalculations.otherCost} USD)
                  </Typography>
                </Typography>
              )}
            </CardContent>
          </Card>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Günlük Efor Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={dailyEfforts}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="hours"
                          stroke="#1976d2"
                          name="Saat"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Mağaza Bazlı Toplam Efor
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={shopHours}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="shop" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                          dataKey="hours"
                          fill="#2e7d32"
                          name="Toplam Saat"
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Görev Dağılımı
                  </Typography>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={taskDistribution}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label
                        >
                          {/* Her dilim için farklı renk vermek */}
                          {taskDistribution.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Detaylı Efor Listesi
                  </Typography>
                  <EffortTable
                    filteredData={filteredData}
                    onUpdateEffort={async (updatedEffort) => {
                      // API çağrısı yapılacak
                      console.log(updatedEffort);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  };

  const EffortTable = ({ filteredData, onUpdateEffort }) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedEffort, setSelectedEffort] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [screenshotsDialogOpen, setScreenshotsDialogOpen] = useState(false);

    const handleScreenshotsClick = (effort) => {
      setSelectedEffort(effort);
      setScreenshotsDialogOpen(true);
    };

    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const searchedData = filteredData
      .filter((item) =>
        Object.values(item).some((value) =>
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        // Tarihleri karşılaştırma (yeniden eskiye)
        const dateA = new Date(a.EffortDate);
        const dateB = new Date(b.EffortDate);

        // Aynı tarihler için saat karşılaştırması
        if (dateA.getTime() === dateB.getTime()) {
          const timeA = a.StartHour.split(":").map(Number);
          const timeB = b.StartHour.split(":").map(Number);

          // Saat karşılaştırması
          if (timeA[0] !== timeB[0]) {
            return timeB[0] - timeA[0];
          }
          // Dakika karşılaştırması
          return timeB[1] - timeA[1];
        }

        return dateB.getTime() - dateA.getTime();
      });
    // Filtrelenmiş ve sayfalama uygulanmış veriler
    const paginatedData = searchedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    // Arama terimini kullanarak verileri filtrele

    const handleEditClick = (effort) => {
      setSelectedEffort(effort);
      setEditDialogOpen(true);
    };

    const handleDetailsClick = (effort) => {
      console.log(effort);
      setSelectedEffort(effort);
      setDetailsDialogOpen(true);
    };

    const handleSave = async (updatedEffort) => {
      console.log(updatedEffort);
      try {
        const indexID = updatedEffort.Id;
        const selectedShop = updatedEffort.Shop;
        const UserID = user?.ID;
        const username = user?.Username;
        const startDate = dayjs(updatedEffort.EffortDate).format("DD/MM/YYYY");
        const startTime = updatedEffort.StartHour;
        const endTime = updatedEffort.EndHour;
        const description = updatedEffort.Explanation;
        const storeLinks = updatedEffort.ShopLinks;
        const sellerLinks = updatedEffort.PurchLinks;
        const SelectedEfforts = updatedEffort.SelectedEfforts.map(
          (item) => `${item}`
        ).join(", ");

        const start = dayjs(`1970-01-01T${updatedEffort.StartHour}`);
        let end = dayjs(`1970-01-01T${updatedEffort.EndHour}`);

        if (end.isBefore(start)) {
          end = end.add(1, "day");
        }

        const totalMinutes = end.diff(start, "minute");
        const hours = Math.floor(totalMinutes / 60); // 2
        const minutes = totalMinutes % 60; // 5

        // Dakika kısmını 2 basamakla padStart yapalım
        const formattedMinutes = String(minutes).padStart(2, "0"); // "05"
        const TimeSummary = `${hours}:${formattedMinutes}`;

        console.log(TimeSummary); // "2:05"
        // Zorunlu alanlar dolu mu diye kontrol
        if (!startDate || !startTime || !endTime) {
          setAlertSeverity("error");
          setAlertMessage("Lütfen tüm zorunlu alanları doldurunuz!");
          setAlertOpen(true);
          return;
        }

        // Kullanıcı bilgisi var mı?
        if (!UserID || !username) {
          setAlertSeverity("error");
          setAlertMessage(
            "Beklenmedik Bir Hata Oluştu. Lütfen Yöneticinizle İletişime Geçiniz!"
          );
          setAlertOpen(true);
          return;
        }

        // Sunucuya güncelleme isteği at
        const response = await axios.post(`${path}/api/UpdateFormData`, {
          indexID,
          UserID,
          username,
          selectedShop,
          startDate,
          startTime,
          endTime,
          SelectedEfforts,
          description,
          storeLinks,
          sellerLinks,
          TimeSummary,
        });

        // Başarılı ise alert göster
        setAlertSeverity("success");
        setAlertMessage("Efor başarıyla güncellendi.");
        setAlertOpen(true);
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage("Efor güncellenirken bir hata oluştu." + error);
        setAlertOpen(true);
      }
    };
    const DetailsDialog = ({ open, onClose, effort }) => {
      if (!effort) return null;

      // Helper function to check if base64 string is valid
      const isValidBase64 = (str) => {
        if (!str) return false;
        try {
          return str.startsWith("data:image") || btoa(atob(str)) === str;
        } catch (err) {
          return false;
        }
      };

      // Get valid images from effort
      const images = ["Image1", "Image2", "Image3"]
        .map((key) => effort[key])
        .filter(isValidBase64);

      const separateLinks = (linkString) => {
        if (!linkString) return [];

        // URL'leri düzgün ayırmak için düzenleme yap
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        return (
          linkString.replace(/(https)(?=:\/\/)/g, " $1").match(urlPattern) || []
        );
      };

      const LinkTable = ({ title, links }) => (
        <div className="space-y-4">
          <h3 className="text-base font-medium">{title}</h3>
          {links.length > 0 ? (
            <table className="w-full border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">Linkler</th>
                </tr>
              </thead>
              <tbody>
                {links.map((link, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="border border-gray-300 px-4 py-2">
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800 hover:underline"
                      >
                        {link}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-gray-500">Link bulunmuyor</p>
          )}
        </div>
      );

      return (
        <Dialog open={open}>
          <DialogTitle className="text-lg font-semibold">
            Ayrıntılı Bilgiler
          </DialogTitle>
          <DialogContent>
            <div className="space-y-6 p-4">
              <div className="space-y-2">
                <h3 className="text-base font-medium">Adet Sayısı:</h3>
                <p>{effort.Piace}</p>
              </div>
              <div className="space-y-6">
                <LinkTable
                  title="Mağaza Linkleri"
                  links={separateLinks(effort.ShopLinks)}
                />
                <LinkTable
                  title="Satıcı Linkleri"
                  links={separateLinks(effort.PurchLinks)}
                />
              </div>

              {/* Resimler */}
              {images.length > 0 && (
                <div className="space-y-2">
                  <h3 className="text-base font-medium">Resimler:</h3>
                  <div className="grid grid-cols-1 gap-4 place-items-center">
                    {images.map((image, index) => (
                      <div key={index} className="relative">
                        <Box
                          component="img"
                          src={
                            image.startsWith("data:image")
                              ? image
                              : `data:image/jpeg;base64,${image}`
                          }
                          alt={`Resim ${index + 1}`}
                          sx={{
                            width: 400,
                            height: 400,
                            objectFit: "contain",
                            borderRadius: "8px",
                            backgroundColor: "gray",
                          }}
                          onError={(e) => {
                            e.target.src = "/api/placeholder/200/200";
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outline">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
    const effortOptions = [
      "MESAJLARA CEVAP",
      "CUSTOMHUB",
      "CUSTOM ORDER HAZIRLANMASI",
      "URUN BULMA",
      "URUN YUKLEME",
      "MAGAZA GENEL KONTROL",
      "TOPLANTI",
      "PIYASA ARSTIRMASI AR-GE",
      "REKLAM OPTIMIZASYONU",
      "URETIM TAKIP VE KONTROL",
      "DIZAYN CIZIMI",
      "MOCKUP EKLEME",
      "TRADEMARK KONTROLU",
      "ARGE",
      "FASHION FOLLOW",
      "YONETIM VE KONTROL",
      "DIGER",
    ];

    const ScreenshotsDialog = ({ open, onClose, effort }) => {
      const [screenshots, setScreenshots] = useState([]);
      const [loading, setLoading] = useState(false);
      const [error, setError] = useState(null);
      const [page, setPage] = useState(0);
      const [itemsPerPage] = useState(60);

      const fetchScreenshots = async () => {
        if (!effort) return;

        setLoading(true);
        setError(null);
        setPage(0); // Sayfayı sıfırla

        try {
          const effortDateLocal = dayjs(effort.EffortDate).format("YYYY-MM-DD");
          const startDateTime = dayjs(
            `${effortDateLocal} ${effort.StartHour}`,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm:ss");
          const endDateTime = dayjs(
            `${effortDateLocal} ${effort.EndHour}`,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm:ss");

          console.log("Start DateTime:", startDateTime);
          console.log("End DateTime:", endDateTime);

          const response = await axios.post(`${path}/api/GetScreenShots`, {
            startDate: startDateTime,
            endDate: endDateTime,
            userId: effort.UserID,
          });

          // Artık sadece dosya yolu ile işlem yapıyoruz
          setScreenshots(response.data);
        } catch (err) {
          console.error("Screenshot fetch error:", err);
          setError("Ekran görüntüleri alınırken bir hata oluştu.");
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        if (open) {
          fetchScreenshots();
        }
      }, [open, effort]);

      // Sayfalama hesaplama
      const pageCount = Math.ceil(screenshots.length / itemsPerPage);
      const currentPageItems = screenshots.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
      );

      return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Ekran Görüntüleri</Typography>
              {screenshots.length > 0 && (
                <Typography variant="body2" color="text.secondary">
                  Toplam: {screenshots.length} görüntü
                </Typography>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={400}
              >
                <Typography sx={{ ml: 2 }}>Yükleniyor...</Typography>
              </Box>
            ) : error ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="error.main"
              >
                {error}
              </Box>
            ) : screenshots.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="text.secondary"
              >
                Bu zaman aralığında ekran görüntüsü bulunmamaktadır.
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gap: 3,
                    justifyItems: "center",
                    mt: 2,
                    mb: 2,
                  }}
                >
                  {currentPageItems.map((screenshot, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: 1000,
                        border: "1px solid",
                        borderColor: "divider",
                        borderRadius: 2,
                        p: 2,
                        boxShadow: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          maxHeight: 700,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          component="img"
                          src={screenshot.ScreenshotPath} // Sunucudan alınan dosya yolu
                          alt={`Ekran Görüntüsü ${index + 1}`}
                          sx={{
                            maxWidth: "100%",
                            height: "auto",
                            objectFit: "contain",
                          }}
                          onError={(e) => {
                            e.target.src = "/api/placeholder/400/300"; // Eğer resim yüklenemezse yedek resim
                          }}
                        />
                      </Box>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        mt={1}
                      >
                        {dayjs(screenshot.UploadDate).format(
                          "DD.MM.YYYY HH:mm:ss"
                        )}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {screenshots.length > itemsPerPage && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    <Pagination
                      count={pageCount}
                      page={page + 1}
                      onChange={(e, newPage) => setPage(newPage - 1)}
                      color="primary"
                      siblingCount={2}
                      boundaryCount={2}
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Kapat
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <div className="space-y-4">
        {/* Arama Alanı */}

        <ScreenshotsDialog
          open={screenshotsDialogOpen}
          onClose={() => setScreenshotsDialogOpen(false)}
          effort={selectedEffort}
        />

        <EditEffortDialog
          open={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          effort={selectedEffort || {}}
          onSave={handleSave}
          shops={shops || []}
          allEffortTypes={effortOptions || []}
        />

        {/* Details Dialog */}
        <DetailsDialog
          open={detailsDialogOpen}
          onClose={() => setDetailsDialogOpen(false)}
          effort={selectedEffort}
        />

        <div className="flex items-center space-x-2 p-4 bg-white rounded-lg shadow">
          <Search className="text-gray-400" />
          <input
            type="text"
            placeholder="Ara..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Tablo */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>İşlemler</TableCell>
                <TableCell>Tarih</TableCell>
                <TableCell>Kullanıcı</TableCell>
                <TableCell>Mağaza</TableCell>
                <TableCell>Başlangıç</TableCell>
                <TableCell>Bitiş</TableCell>
                <TableCell>Toplam Süre</TableCell>
                <TableCell>Görevler</TableCell>
                <TableCell>Ayrıntılar</TableCell>
                {user.Admin === 1 && <TableCell>Ekran Görüntüleri</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((item) => (
                <TableRow key={item.Id}>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleEditClick(item)}
                      color="primary"
                    ></IconButton>
                  </TableCell>
                  <TableCell>
                    {new Date(item.EffortDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{item.UserName}</TableCell>
                  <TableCell>{item.Shop}</TableCell>
                  <TableCell>{item.StartHour}</TableCell>
                  <TableCell>{item.EndHour}</TableCell>
                  <TableCell>{item.TimeSummary}</TableCell>
                  <TableCell>
                    {item.SelectedEfforts === "DIGER"
                      ? `${item.SelectedEfforts} -> ${item.Explanation}`
                      : item.SelectedEfforts}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleDetailsClick(item)}
                      color="info"
                    >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                  {user.Admin === 1 && (
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleScreenshotsClick(item)}
                        color="secondary"
                      >
                        <ImageIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* Sayfalama */}
          <TablePagination
            component="div"
            count={searchedData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Sayfa başına satır:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} arası, toplam: ${count}`
            }
          />
        </TableContainer>
      </div>
    );
  };

  const EditEffortDialog = ({
    effort,
    open,
    onClose,
    onSave,
    shops,
    allEffortTypes,
  }) => {
    const [formData, setFormData] = useState({
      StartHour: effort.StartHour || "",
      EndHour: effort.EndHour || "",
      Shop: effort.Shop || "",
      SelectedEfforts: Array.isArray(effort.SelectedEfforts)
        ? effort.SelectedEfforts
        : effort.SelectedEfforts?.split(",").map((item) => item.trim()) || [],
      Explanation: effort.Explanation || "",
      ShopLinks: effort.ShopLinks || "",
      PurchLinks: effort.PurchLinks || "",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === "SelectedEfforts") {
        setFormData((prev) => ({
          ...prev,
          SelectedEfforts: typeof value === "string" ? value.split(",") : value,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    };

    const handleSubmit = () => {
      onSave({ ...effort, ...formData });
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Efor Kaydını Güncelle</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              mt: 2,
            }}
          >
            <FormControl fullWidth sx={{}}>
              <TextField
                name="StartHour"
                label="Başlangıç Saati"
                type="time"
                value={formData.StartHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />
            </FormControl>

            <FormControl fullWidth sx={{}}>
              <TextField
                name="EndHour"
                label="Bitiş Saati"
                type="time"
                value={formData.EndHour}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Mağaza</InputLabel>
            <Select
              name="Shop"
              value={formData.Shop}
              onChange={handleChange}
              label="Mağaza"
            >
              {shops.map((shop, index) => (
                <MenuItem key={index} value={shop}>
                  {shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Yapılan İşler</InputLabel>
            <Select
              name="SelectedEfforts"
              multiple
              value={formData.SelectedEfforts}
              onChange={handleChange}
              label="Yapılan İşler"
            >
              {allEffortTypes.map((effort, index) => (
                <MenuItem key={index} value={effort}>
                  {effort}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="Explanation"
              label="Açıklama"
              multiline
              rows={4}
              value={formData.Explanation}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="storeLinks"
              label="Mağaza Linkleri"
              multiline
              rows={2}
              value={formData.ShopLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              name="sellerLinks"
              label="Satıcı Linkleri"
              multiline
              rows={2}
              value={formData.PurchLinks}
              onChange={handleChange}
              fullWidth
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            İptal
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Güncelle
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const calculateMetrics = (shop, startDate, endDate, userEarnings) => {
    const expenses = calculateExpenses(
      shop.SelectedShop,
      shop.StartDate,
      shop.EndDate
    ).toFixed(2);

    const etsyMoneyDeposit = (
      Math.abs(shop.salesSummaryDiff) -
      (Math.abs(shop.listingFeesDiff) +
        Math.abs(shop.marketingFeesDiff) +
        Math.abs(shop.ShippingFeesDiff))
    ).toFixed(2);
    const shippingExpenses = Math.abs(shop.ShippingFeesDiff);
    const totalSalesAmount = Math.abs(Number(shop.CSVSalesSummary));
    const totalRefundAmount = Math.abs(Number(shop.totalRefundsAmounts));
    const totalMarketingFees = Math.abs(shop.marketingFeesDiff);
    const totalFees = Math.abs(shop.listingFeesDiff);
    const shippingFees = Math.abs(shop.ShippingFeesDiff);
    const tpcExpenses = (
      Math.abs(shop.productTotalSum) +
      Math.abs(shop.productCustomCost) +
      Math.abs(shop.returnAndExchange)
    ).toFixed(2);

    const formattedStartDate = shop.StartDate ? new Date(shop.StartDate) : null;
    const formattedEndDate = shop.EndDate ? new Date(shop.EndDate) : null;

    if (formattedStartDate) {
      formattedStartDate.setDate(formattedStartDate.getDate());
    }
    if (formattedEndDate) {
      formattedEndDate.setDate(formattedEndDate.getDate());
    }

    const StartDateFormat = formattedStartDate
      ? formattedStartDate.toISOString().split("T")[0]
      : "";
    const EndDateFormat = formattedEndDate
      ? formattedEndDate.toISOString().split("T")[0]
      : "";

    const userEarningsCalc = calculateUserEarningsInRange(
      shop.Responsible,
      StartDateFormat,
      EndDateFormat
    );

    const selectedEarnings =
      userEarningsCalc.shops?.[shop.SelectedShop]?.earnings.toFixed(2);

    const shopExpenses =
      Math.abs(shop.ShippingFeesCSV) +
      Math.abs(shop.productCustomCost) +
      Math.abs(shop.returnAndExchange) +
      Math.abs(shop.productTotalSum);

    const totalProfit = (
      Math.abs(shop.PaymentFee) -
      shopExpenses -
      expenses
    ).toFixed(2);

    return {
      shopExpenses,
      totalProfit,
      etsyMoneyDeposit,
      shippingExpenses,
      totalSalesAmount,
      totalRefundAmount,
      totalMarketingFees,
      totalFees,
      shippingFees,
      tpcExpenses,
      expenses,
      StartDateFormat,
      EndDateFormat,
      selectedEarnings,
    };
  };

  const calculateExpenses = (shop, start, end) => {
    const userStart = start ? new Date(start) : null;
    const userEnd = end ? new Date(end) : null;

    return shopExpensesData
      .filter((expense) => {
        return expense.shop === shop;
      })
      .reduce((total, expense) => {
        const expenseStart = new Date(expense.startDate);
        const expenseEnd = new Date(expense.endDate);

        if (!userStart || !userEnd) {
          return total + Number(expense.amount);
        }
        if (expenseEnd < userStart || expenseStart > userEnd) {
          return total;
        }

        const overlapStart =
          expenseStart < userStart ? userStart : expenseStart;
        const overlapEnd = expenseEnd > userEnd ? userEnd : expenseEnd;

        const totalDays =
          Math.ceil((expenseEnd - expenseStart) / (1000 * 3600 * 24)) + 1;
        const overlapDays =
          Math.ceil((overlapEnd - overlapStart) / (1000 * 3600 * 24)) + 1;

        if (overlapDays < 0) {
          return total;
        }

        const dailyCost = Number(expense.amount) / totalDays;
        const partialCost = dailyCost * overlapDays;

        return total + partialCost;
      }, 0);
  };

  const calculateUserEarningsInRange = (username, startDate, endDate) => {
    const userEarnings = {
      argeHours: 0,
      argeEarnings: 0,
      otherHours: 0,
      otherEarnings: 0,
      totalHours: 0,
      totalEarnings: 0,
      shops: {},
    };

    userData.forEach((record) => {
      // Kullanıcı adı eşleşmiyorsa atla
      if (record.UserName !== username) return;

      // Tarih filtresi
      const recordDate = new Date(record.EffortDate);
      recordDate.setHours(0, 0, 0, 0);

      if (startDate) {
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        if (recordDate < start) return;
      }

      if (endDate) {
        const end = new Date(endDate);
        end.setHours(0, 0, 0, 0);
        if (recordDate > end) return;
      }

      // Süre hesaplama
      const [hours, minutes] = record.TimeSummary.split(":").map(Number);
      const durationHours = hours + minutes / 60;

      // Ücret bulma
      const userFee = userEffortsFees.find(
        (fee) => fee.userId === record.UserID
      );
      if (!userFee) return;

      // Kazanç hesaplama
      if (record.Shop === "Arge") {
        const rate = parseFloat(userFee.effortType2Fee) || 0;
        userEarnings.argeHours += durationHours;
        userEarnings.argeEarnings += durationHours * rate;
      } else {
        const rate = parseFloat(userFee.effortType1Fee) || 0;
        userEarnings.otherHours += durationHours;
        userEarnings.otherEarnings += durationHours * rate;

        // Mağaza detayları
        if (!userEarnings.shops[record.Shop]) {
          userEarnings.shops[record.Shop] = {
            hours: 0,
            earnings: 0,
          };
        }
        userEarnings.shops[record.Shop].hours += durationHours;
        userEarnings.shops[record.Shop].earnings += durationHours * rate;
      }

      // Toplamlar
      userEarnings.totalHours += durationHours;
      userEarnings.totalEarnings =
        userEarnings.argeEarnings + userEarnings.otherEarnings;
    });

    return userEarnings;
  };

  const ShopData = () => {
    const formatCurrency = (value) =>
      Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    const [selectedMetrics, setSelectedMetrics] = useState(["totalProfit"]);

    const metricOptions = [
      { field: "totalProfit", headerName: "Kar/Zarar" },
      { field: "etsyMoneyDeposit", headerName: "Etsy Yatması Beklenen Para" },
      { field: "totalSales", headerName: "Toplam Satış" },
      { field: "PaymentFee", headerName: "Etsy Yatan Para" },
      { field: "CSVSalesSummary", headerName: "Toplam Satış Adedi" },
      { field: "totalRefundsAmounts", headerName: "İadeler" },
      { field: "listingFeesDiff", headerName: "Liste Ücretleri" },
      { field: "marketingFeesDiff", headerName: "Pazarlama Ücretleri" },
      { field: "ShippingFeesDiff", headerName: "Kargo Ücretleri" },
      { field: "productTotalSum", headerName: "Toplam Ürün Tutarı CH" },
      { field: "productCustomCost", headerName: "Ürün Custom Maliyeti CH" },
      { field: "returnAndExchange", headerName: "İade ve Değişim CH" },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH" },
      { field: "expenses", headerName: "Diğer Giderler" },
    ];

    const Allshops = ["all", ...new Set(data.map((item) => item.SelectedShop))];
    const AllPersonel = [
      "all",
      ...new Set(data.map((item) => item.Responsible)),
    ];

    const filteredData = Array.from(
      new Map(
        data
          .filter((item) => {
            const itemDate = new Date(item.StartDate);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            const matchesShop =
              selectedShop === "all" || item.SelectedShop === selectedShop;
            const matchesPersonnel =
              selectedUser === "all" || item.Responsible === selectedUser;
            const matchesDate =
              (!start || itemDate >= start) && (!end || itemDate <= end);

            return matchesShop && matchesPersonnel && matchesDate; // Added personnel filter
          })
          .map((item) => [item.ID, { ...item }])
      ).values()
    ).map((item) => ({
      ...item,
      ...calculateMetrics(item, startDate, endDate, userEarnings),
    }));

    let uniqueShopResponsibles = [];
    if (selectedUser !== "all" || selectedShop !== "all") {
      uniqueShopResponsibles = [
        ...new Set(
          filteredData
            .filter((item) => item.Responsible)
            .map((item) => item.Responsible)
        ),
      ];
    }
    const expenses =
      filteredData.reduce((sum, item) => sum + item.expenses, 0) /
      filteredData.length;

    const totalExpenses = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.expenses),
      0
    );

    const totalSum = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalSalesAmount),
      0
    );

    const shippingFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.shippingExpenses),
      0
    );
    const shippingFeesCustomHub = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.ShippingFeesCSV),
      0
    );
    const productCustomCost = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.productCustomCost),
      0
    );
    const totelFees = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalFees),
      0
    );
    const totalMarketing = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.totalMarketingFees),
      0
    );

    const etsyRealDeposit = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.PaymentFee),
      0
    );

    const totalSalesSummary = filteredData.reduce(
      (sum, item) => sum + Math.abs(item.salesSummaryDiff),
      0
    );

    const totalRefundAmount = filteredData
      .reduce((sum, item) => sum + item.totalRefundAmount, 0)
      .toFixed(0);

    const totalSalesAmount = filteredData.reduce(
      (sum, item) => sum + item.totalSalesAmount,
      0
    );

    const totalPersonelExpenses = filteredData.reduce(
      (sum, item) => sum + item.selectedEarnings,
      0
    );

    const totalMarketingPercentage =
      parseFloat((totalMarketing / totalSalesSummary).toFixed(4)) * 100;
    const totalFeePercentage =
      parseFloat((totelFees / totalSalesSummary).toFixed(4)) * 100;
    const totalShippingPercentage =
      parseFloat((shippingFeesCustomHub / totalSalesSummary).toFixed(4)) * 100;
    const totalExpensesPercentage =
      parseFloat((totalExpenses / totalSalesSummary).toFixed(4)) * 100;
    const totalReturnPercentage =
      parseFloat((totalRefundAmount / totalSalesAmount).toFixed(4)) * 100;

    const totalProfitSum = filteredData.reduce((sum, item) => {
      const profit = Number(item.totalProfit);
      return !isNaN(profit) ? sum + profit : sum;
    }, 0);

    const totalProfitViaUnit = totalProfitSum / totalSum;
    const totalMarketingViaUnit = totalMarketing / totalSum;
    const totalFeeViaUnit = totelFees / totalSum;
    const shippingFeeViaUnit = shippingFeesCustomHub / totalSum;
    const expenesViaUnit = totalExpenses / totalSum;

    let uniqueShops = [];
    if (selectedUser !== "all" || selectedShop !== "all") {
      uniqueShops = [...new Set(filteredData.map((item) => item.SelectedShop))];
    }

    const columns = [
      { field: "ID", headerName: "ID", width: 50, backgroundColor: "#5CB338" },
      { field: "Username", headerName: "Kullanıcı Adı", width: 70 },
      { field: "SelectedShop", headerName: "Mağaza", width: 130 },
      { field: "StartDateFormat", headerName: "Başlangıç Tarihi", width: 130 },
      {
        field: "EndDateFormat",
        headerName: "Bitiş Tarihi",
        width: 130,
      },
      {
        field: "totalProfit",
        headerName: "Kar/Zarar",
        width: 130,
        renderCell: (params) => {
          const value = parseFloat(params.value) || 0; // Sayıya çevir ve hata önle
          const bgColor = value < 0 ? "#D84040" : "#5CB338"; // Negatifse kırmızı, pozitifse yeşil

          return (
            <Box
              sx={{
                backgroundColor: bgColor,
                color: "white",
                p: 1,
                borderRadius: 1,
                textAlign: "center",
                width: "100%",
              }}
            >
              {params.value}
            </Box>
          );
        },
      },
      { field: "PaymentFee", headerName: "Etsy Yatan para", width: 130 },
      {
        field: "CSVSalesSummary",
        headerName: "Toplam Satış Adedi",
        width: 160,
      },
      { field: "totalRefundsAmounts", headerName: "İadeler", width: 130 },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH", width: 130 },
      {
        field: "productTotalSum",
        headerName: "Toplam Ürün Tutarı CH",
        width: 150,
      },
      { field: "expenses", headerName: "Diğer Giderler", width: 130 },
      {
        field: "selectedEarnings",
        headerName: "Personel Giderleri",
        width: 130,
      },
      {
        field: "etsyMoneyDeposit",
        headerName: "Etsy Yatması Beklenen Para",
        width: 130,
      },
      { field: "totalSales", headerName: "Toplam Satış", width: 130 },
      {
        field: "listingFeesABS",
        headerName: "Liste Ücretleri Girilen",
        width: 130,
      },
      {
        field: "listingFeesDiff",
        headerName: "Liste Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "marketingFeesABS",
        headerName: "Pazarlama Ücretleri Girilen",
        width: 130,
      },
      {
        field: "marketingFeesDiff",
        headerName: "Pazarlama Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "ShippingFeesABS",
        headerName: "Kargo Ücretleri Girilen",
        width: 130,
      },
      {
        field: "ShippingFeesDiff",
        headerName: "Kargo Ücretleri Haftalık",
        width: 130,
      },
      {
        field: "salesSummary",
        headerName: "Sales Summary Girilen",
        width: 130,
      },
      {
        field: "salesSummaryDiff",
        headerName: "Sales Summary Haftalık",
        width: 130,
      },

      {
        field: "productCustomCost",
        headerName: "Ürün Custom Maliyeti CH",
        width: 160,
      },
      {
        field: "returnAndExchange",
        headerName: "İade ve Değişim CH",
        width: 160,
      },
      { field: "piaceInput", headerName: "Adet Girişi", width: 130 },
    ];
    const COLORS = ["#1976d2", "#2e7d32", "#ed6c02", "#9c27b0", "#e91e63"];

    const MetricCard = ({ title, value }) => {
      let titleColor;
      if (
        title === "Toplam Brüt Kar/Zarar" ||
        title === "Adet Başına Kar/Zarar"
      )
        titleColor = "#5CB338";
      else if (
        title === "Adet Başına Kargo" ||
        title === "Adet Başına Diğer Gider Masrafları" ||
        title === "Adet Başına Fees" ||
        title === "Adet Başına Marketing"
      )
        titleColor = "#7695FF";
      else titleColor = "#FADFA1";

      // Sayısal değeri kontrol et
      const numericValue =
        typeof value === "string"
          ? parseFloat(value.replace(/[^0-9.-]/g, ""))
          : parseFloat(value) || 0; // Eğer `value` undefined veya NaN ise varsayılan 0 veriyoruz

      if (
        (title === "Toplam Brüt Kar/Zarar" ||
          title === "Adet Başına Kar/Zarar") &&
        numericValue < 0
      ) {
        titleColor = "#D84040";
      }

      return (
        <Card>
          <CardContent style={{ backgroundColor: titleColor }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="h4">{value}</Typography>
          </CardContent>
        </Card>
      );
    };

    const exportToExcel = () => {
      const headers = columns.map((col) => col.headerName);

      const excelData = filteredData.map((row) =>
        columns.reduce((acc, col) => {
          acc[col.headerName] = row[col.field];
          return acc;
        }, {})
      );

      const worksheet = XLSX.utils.json_to_sheet(excelData, {
        header: headers,
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "exported_data.xlsx");
    };

    const exportShopFees = async () => {
      try {
        const response = await axios.get(path + "/api/GetShopFees");
        const shopFees = response.data;

        const headers = [
          "SelectedShop",
          "StartDate",
          "EndDate",
          "PaymentDate",
          "PaymentFee",
        ];

        const excelData = shopFees.map((row) => ({
          SelectedShop: row.SelectedShop,
          StartDate: row.StartDate,
          EndDate: row.EndDate,
          PaymentDate: row.PaymentDate,
          PaymentFee: row.PaymentFee,
        }));

        const worksheet = XLSX.utils.json_to_sheet(excelData, {
          header: headers,
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "ShopFees");

        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        saveAs(data, "shop_fees.xlsx");
      } catch (error) {
        console.error("Error fetching shop fees:", error);
      }
    };

    const handleShopChange = (event) => {
      setSelectedShop(event.target.value);
      setSelectedUser("all"); // Mağaza değiştiğinde personeli sıfırla
    };

    // Personel seçildiğinde mağazayı "all" yapacak handler
    const handleUserChange = (event) => {
      setSelectedUser(event.target.value);
      setSelectedShop("all");
    };

    const [currentShopIndex, setCurrentShopIndex] = useState(
      Allshops.indexOf(selectedShop) || 0
    );

    useEffect(() => {
      setCurrentShopIndex(Allshops.indexOf(selectedShop));
    }, [selectedShop]);

    const handleNextShop = () => {
      if (currentShopIndex < Allshops.length - 1) {
        const nextShop = Allshops[currentShopIndex + 1];
        setSelectedShop(nextShop);
        setSelectedUser("all");
      }
    };

    const handlePreviousShop = () => {
      if (currentShopIndex > 0) {
        const prevShop = Allshops[currentShopIndex - 1];
        setSelectedShop(prevShop);
        setSelectedUser("all");
      }
    };

    const [currentPersonnelIndex, setCurrentPersonnelIndex] = useState(
      AllPersonel.indexOf(selectedUser) || 0
    );

    useEffect(() => {
      setCurrentPersonnelIndex(AllPersonel.indexOf(selectedUser));
    }, [selectedUser]);

    const handleNextPersonnel = () => {
      if (currentPersonnelIndex < AllPersonel.length - 1) {
        const nextPersonnel = AllPersonel[currentPersonnelIndex + 1];
        setSelectedUser(nextPersonnel);
        setSelectedShop("all");
      }
    };

    const handlePreviousPersonnel = () => {
      if (currentPersonnelIndex > 0) {
        const prevPersonnel = AllPersonel[currentPersonnelIndex - 1];
        setSelectedUser(prevPersonnel);
        setSelectedShop("all");
      }
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              {/* Filters */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      {/* İlk Satır: Mağaza ve Personel Seçimleri */}
                      <Grid
                        item
                        xs={12}
                        md={5}
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <InputLabel>Mağaza Seç</InputLabel>
                            <Select
                              value={selectedShop}
                              label="Mağaza Seç"
                              onChange={handleShopChange}
                            >
                              {Allshops.map((shop) => (
                                <MenuItem key={shop} value={shop}>
                                  {shop === "all" ? "Tüm Mağazalar" : shop}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <ButtonGroup variant="outlined" size="small">
                            <Button
                              onClick={handlePreviousShop}
                              disabled={currentShopIndex <= 0}
                            >
                              <ArrowBackIcon fontSize="small" />
                            </Button>
                            <Button
                              onClick={handleNextShop}
                              disabled={currentShopIndex >= Allshops.length - 1}
                            >
                              <ArrowForwardIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={5}
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={10}>
                          <FormControl fullWidth>
                            <InputLabel>Personel Seç</InputLabel>
                            <Select
                              value={selectedUser}
                              label="Personel Seç"
                              onChange={handleUserChange}
                            >
                              {AllPersonel.map((personnel) => (
                                <MenuItem key={personnel} value={personnel}>
                                  {personnel === "all"
                                    ? "Tüm Personel"
                                    : personnel}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <ButtonGroup variant="outlined" size="small">
                            <Button
                              onClick={handlePreviousPersonnel}
                              disabled={currentPersonnelIndex <= 0}
                            >
                              <ArrowBackIcon fontSize="small" />
                            </Button>
                            <Button
                              onClick={handleNextPersonnel}
                              disabled={
                                currentPersonnelIndex >= AllPersonel.length - 1
                              }
                            >
                              <ArrowForwardIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>

                      {/* İkinci Satır: Tarih Seçimleri */}
                      <Grid item xs={12} md={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <DatePicker
                              label="Başlangıç Tarihi"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                              format="dd/MM/yyyy"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DatePicker
                              label="Bitiş Tarihi"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                              format="dd/MM/yyyy"
                              minDate={startDate}
                            />
                          </Grid>

                          <Grid item xs={12} sm={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleFetchData}
                              disabled={loading}
                              fullWidth
                            >
                              {loading
                                ? "Yükleniyor..."
                                : "Kullanıcı Verilerini Getir"}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {error && (
                  <Grid item xs={12}>
                    <p style={{ color: "red" }}>{error}</p>
                  </Grid>
                )}
              </Grid>

              {/* Summary Cards - First Row */}
              <Grid item xs={12} md={3} sx>
                <MetricCard
                  className="custom-card"
                  title="Toplam Brüt Kar/Zarar"
                  value={formatCurrency(totalProfitSum)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Etsy Yatan Para"
                  value={formatCurrency(etsyRealDeposit.toFixed(2))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Toplam Satış Miktarı (Adet)"
                  value={totalSalesAmount}
                />
              </Grid>

              {/* Summary Cards - Second Row */}
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Toplam İade Miktarı (Adet)"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{totalRefundAmount}</span>
                      <span>{"%" + totalReturnPercentage.toFixed(2)}</span>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Net Satış"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>
                        {formatCurrency(totalSalesSummary.toFixed(2))}
                      </span>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Etsy Yatması Beklenen Para"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Number(item.etsyMoneyDeposit),
                      0
                    )
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Reklam Giderleri"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.totalMarketingFees),
                      0
                    )
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="ETSY Masrafları"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.totalFees),
                      0
                    )
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Kar/Zarar"
                  value={"$" + totalProfitViaUnit.toFixed(2)}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="TPC Giderleri"
                  value={formatCurrency(
                    filteredData.reduce(
                      (sum, item) => sum + Math.abs(item.tpcExpenses),
                      0
                    )
                  )}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Kargo Giderleri"
                  value={formatCurrency(shippingFeesCustomHub.toFixed(2))}
                />
              </Grid>

              {/* Unit Metrics */}
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Diğer Giderler"
                  value={formatCurrency(totalExpenses.toFixed(2))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Marketing"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{"$" + totalMarketingViaUnit.toFixed(2)}</span>
                      <span>{"%" + totalMarketingPercentage.toFixed(2)}</span>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Fees"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{"$" + totalFeeViaUnit.toFixed(2)}</span>
                      <span>{"%" + totalFeePercentage.toFixed(2)}</span>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Kargo"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{"$" + shippingFeeViaUnit.toFixed(2)}</span>
                      <span>{"%" + totalShippingPercentage.toFixed(2)}</span>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <MetricCard
                  title="Adet Başına Diğer Gider Masrafları"
                  value={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span>{"$" + expenesViaUnit.toFixed(2)}</span>
                      <span>{"%" + totalExpensesPercentage.toFixed(2)}</span>
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Card sx={{ mb: 0 }}>
                  <CardContent>
                    <Typography variant="h6" color="primary">
                      Mağaza Personeli: {uniqueShopResponsibles}
                    </Typography>

                    <Typography variant="h6" color="primary">
                      Personel Sorumlu Mağazalar: {uniqueShops.join(", ")}
                    </Typography>

                    <Typography variant="h6" color="secondary">
                      Toplam Çalışma Süresi: {totalWorkHours} saat
                    </Typography>

                    {(user.Admin === 1 || user.Username === selectedUser) && (
                      <Typography variant="h6" color="secondary">
                        Toplam Maliyet: {totalCalculations.totalCost} USD
                        <Typography
                          component="span"
                          sx={{
                            color: "warning.main", // sarı renk
                            fontSize: "0.8em", // ana yazıdan daha küçük
                            marginLeft: 1, // sol boşluk
                          }}
                        >
                          (ARGE: {totalCalculations.argeCost} USD, Diğer:{" "}
                          {totalCalculations.otherCost} USD)
                        </Typography>
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>

              {/* Charts */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl fullWidth>
                          <InputLabel>Gösterilecek Veri</InputLabel>
                          <Select
                            multiple
                            value={selectedMetrics}
                            label="Gösterilecek Veriler"
                            onChange={(e) => setSelectedMetrics(e.target.value)}
                          >
                            {metricOptions.map((option) => (
                              <MenuItem key={option.field} value={option.field}>
                                {option.headerName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {/* Dynamic Bar Chart */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Veri Karşılaştırma Grafiği
                    </Typography>
                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer>
                        <LineChart
                          data={filteredData
                            .sort(
                              (a, b) =>
                                new Date(a.StartDate) - new Date(b.StartDate)
                            )
                            .map((item) => ({
                              ...item,
                              ...selectedMetrics.reduce(
                                (acc, metric) => ({
                                  ...acc,
                                  [metric]: Number(item[metric]) || 0,
                                }),
                                {}
                              ),
                            }))}
                          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="StartDate"
                            tickFormatter={(date) =>
                              new Date(date).toLocaleDateString("tr-TR")
                            }
                            interval="preserveStartEnd"
                          />
                          <YAxis />
                          <Tooltip
                            formatter={(value, name, props) => {
                              if (typeof value === "number") {
                                return name.includes("Fee") ||
                                  name.includes("Price") ||
                                  name.includes("Cost") ||
                                  name.includes("Profit")
                                  ? formatCurrency(value)
                                  : value.toLocaleString();
                              }
                              return value;
                            }}
                            labelFormatter={(label) =>
                              new Date(label).toLocaleDateString("tr-TR")
                            }
                          />
                          <Legend />
                          {selectedMetrics.map((metric, index) => {
                            const metricOption = metricOptions.find(
                              (option) => option.field === metric
                            );
                            return (
                              <Line
                                key={metric}
                                type="linear"
                                dataKey={metric}
                                name={metricOption?.headerName}
                                stroke={COLORS[index % COLORS.length]} // Renk döngüsü için modulo kullanımı
                                strokeWidth={2}
                                dot={{ r: 4, strokeWidth: 1, fill: "#fff" }}
                                activeDot={{ r: 6, strokeWidth: 1 }}
                              />
                            );
                          })}
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Günlük Efor Dağılımı
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={dailyEfforts}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="date" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="hours"
                            stroke="#1976d2"
                            name="Saat"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Görev Dağılımı
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={taskDistribution}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            label
                          >
                            {/* Her dilim için farklı renk vermek */}
                            {taskDistribution.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Data Table */}
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Detaylı Veri
                    </Typography>
                    <Box sx={{ height: 400 }}>
                      <DataGrid
                        rows={filteredData}
                        getRowId={(row) => row.ID}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        disableSelectionOnClick
                      />
                    </Box>
                  </CardContent>
                </Card>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportToExcel}
                  >
                    Excel'e Aktar
                  </Button>
                  {user.Admin === 1 || user.Username === "osman kale" ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={exportShopFees}
                    >
                      Etsy Yatan Para Excele Aktar
                    </Button>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </LocalizationProvider>
    );
  };

  const calculateMetricsCompare = (
    shopItem,
    startDate,
    endDate,
    shopExpensesData
  ) => {
    const expenses = calculateExpenses(
      shopItem.SelectedShop,
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
      shopExpensesData
    );

    const shopExpenses =
      Math.abs(shopItem.ShippingFeesCSV) +
      Math.abs(shopItem.productCustomCost) +
      Math.abs(shopItem.returnAndExchange) +
      Math.abs(shopItem.productTotalSum);

    const totalProfit =
      Math.abs(shopItem.PaymentFee) - shopExpenses - Math.abs(expenses);
    console.log("totalProfit", totalProfit);
    const etsyMoneyDeposit =
      Math.abs(shopItem.salesSummaryDiff) -
      (Math.abs(shopItem.listingFeesDiff) +
        Math.abs(shopItem.marketingFeesDiff) +
        Math.abs(shopItem.ShippingFeesDiff));

    // Diğer alanlar...
    const shippingExpenses = Math.abs(shopItem.ShippingFeesDiff);
    const totalSalesAmount = Math.abs(Number(shopItem.CSVSalesSummary));
    const totalRefundAmount = Math.abs(Number(shopItem.totalRefundsAmounts));
    const totalMarketingFees = Math.abs(shopItem.marketingFeesDiff);
    const totalFees = Math.abs(shopItem.listingFeesDiff);
    const shippingFees = Math.abs(shopItem.ShippingFeesDiff);
    const tpcExpenses =
      Math.abs(shopItem.productTotalSum) +
      Math.abs(shopItem.productCustomCost) +
      Math.abs(shopItem.returnAndExchange);

    // İsteğe bağlı tarih formatlamaları
    const StartDateFormat = shopItem.StartDate
      ? new Date(shopItem.StartDate).toISOString().split("T")[0]
      : "";
    const EndDateFormat = shopItem.EndDate
      ? new Date(shopItem.EndDate).toISOString().split("T")[0]
      : "";

    return {
      // CompareShops tarafında tabloya/grafiğe göndermek istediğiniz alanlar
      ID: shopItem.ID,
      StartDate: shopItem.StartDate,
      SelectedShop: shopItem.SelectedShop,
      totalProfit,
      etsyMoneyDeposit,
      shippingExpenses,
      totalSalesAmount,
      totalRefundAmount,
      totalMarketingFees,
      totalFees,
      shippingFees,
      tpcExpenses,
      expenses,
      StartDateFormat,
      EndDateFormat,

      // Elinizde varsa PaymentFee gibi diğer sahalar
      PaymentFee: shopItem.PaymentFee,
      CSVSalesSummary: shopItem.CSVSalesSummary,
      listingFees: shopItem.listingFeesDiff,
      marketingFees: shopItem.marketingFeesDiff,
      // ...
    };
  };

  const CompareShops = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [comparisonItems, setComparisonItems] = useState([]);
    const [currentShop, setCurrentShop] = useState("all");
    const [currentUser, setCurrentUser] = useState("all");

    const [selectedMetrics, setSelectedMetrics] = useState(["totalProfit"]);

    const Allshops = ["all", "AUTHENTIC", "MODTREND", "Shop3"];
    const AllPersonel = ["all", "User1", "User2", "User3"];

    const metricOptions = [
      { field: "totalProfit", headerName: "Kar/Zarar" },
      { field: "etsyMoneyDeposit", headerName: "Etsy Yatması Beklenen Para" },
      { field: "totalSales", headerName: "Toplam Satış" },
      { field: "PaymentFee", headerName: "Etsy Yatan Para" },
      { field: "CSVSalesSummary", headerName: "Toplam Satış Adedi" },
      { field: "totalRefundsAmounts", headerName: "İadeler" },
      { field: "listingFees", headerName: "Liste Ücretleri" },
      { field: "marketingFees", headerName: "Pazarlama Ücretleri" },
      { field: "ShippingFees", headerName: "Kargo Ücretleri" },
      { field: "productTotalSum", headerName: "Toplam Ürün Tutarı CH" },
      { field: "productCustomCost", headerName: "Ürün Custom Maliyeti CH" },
      { field: "returnAndExchange", headerName: "İade ve Değişim CH" },
      { field: "ShippingFeesCSV", headerName: "Kargo Gider CH" },
      { field: "expenses", headerName: "Diğer Giderler" },
    ];

    // Format currency
    const formatCurrency = (value) =>
      Number(value).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

    const addToComparison = () => {
      const newItemId = Date.now().toString();

      const filteredData = generateRealData(
        currentShop,
        currentUser,
        startDate,
        endDate,
        data, // <-- Sizin gerçek veri setiniz
        shopExpensesData
      );

      const newItem = {
        id: newItemId,
        shop: currentShop,
        user: currentUser,
        startDate: startDate
          ? new Date(startDate).toISOString().split("T")[0]
          : null,
        endDate: endDate ? new Date(endDate).toISOString().split("T")[0] : null,
        data: filteredData,
        summary: calculateSummaryMetrics(filteredData),
      };

      setComparisonItems([...comparisonItems, newItem]);
    };

    const removeComparisonItem = (itemId) => {
      setComparisonItems(comparisonItems.filter((item) => item.id !== itemId));
    };

    const generateRealData = (
      selectedShop,
      selectedUser,
      startDate,
      endDate,
      allData,
      shopExpensesData
    ) => {
      // 1) Filtreleme (Mağaza, Personel, Tarih)
      const filtered = allData.filter((item) => {
        // Mağaza filtre
        const shopMatch =
          selectedShop === "all" || item.SelectedShop === selectedShop;
        // Personel filtre
        const userMatch =
          selectedUser === "all" || item.Responsible === selectedUser;
        // Tarih filtre
        const itemDate = new Date(item.StartDate);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        const dateMatch =
          (!start || itemDate >= start) && (!end || itemDate <= end);

        return shopMatch && userMatch && dateMatch;
      });

      // 2) Her bir kaydı hesaplayalım
      const calculatedData = filtered.map((shopItem) =>
        calculateMetricsCompare(shopItem, startDate, endDate, shopExpensesData)
      );

      return calculatedData;
    };

    const calculateSummaryMetrics = (data) => {
      const totalProfit = data.reduce(
        (sum, item) => sum + (parseFloat(item.totalProfit) || 0),
        0
      );
      const etsyDeposit = data.reduce(
        (sum, item) => sum + (parseFloat(item.PaymentFee) || 0),
        0
      );
      const totalSales = data.reduce(
        (sum, item) => sum + (parseFloat(item.totalSales) || 0),
        0
      );
      const salesCount = data.reduce(
        (sum, item) => sum + (parseFloat(item.CSVSalesSummary) || 0),
        0
      );
      const expenses = data.reduce(
        (sum, item) => sum + (parseFloat(item.expenses) || 0),
        0
      );

      return {
        totalProfit,
        etsyDeposit,
        totalSales,
        salesCount,
        expenses,
      };
    };

    const prepareChartData = () => {
      if (comparisonItems.length === 0) return [];

      const chartData = [];

      comparisonItems.forEach((item) => {
        selectedMetrics.forEach((metric) => {
          let value = 0;

          // Calculate total for the selected metric
          if (metric === "totalProfit") {
            value = item.summary.totalProfit;
          } else if (metric === "PaymentFee") {
            value = item.summary.etsyDeposit;
          } else if (metric === "totalSales") {
            value = item.summary.totalSales;
          } else if (metric === "CSVSalesSummary") {
            value = item.summary.salesCount;
          } else if (metric === "expenses") {
            value = item.summary.expenses;
          } else {
            // Calculate other metrics
            value = item.data.reduce(
              (sum, dataItem) => sum + (parseFloat(dataItem[metric]) || 0),
              0
            );
          }

          chartData.push({
            name: `${item.shop === "all" ? "Tüm Mağazalar" : item.shop}${
              item.user !== "all" ? ` (${item.user})` : ""
            }`,
            metric: metricOptions.find((opt) => opt.field === metric)
              .headerName,
            value: value,
          });
        });
      });

      return chartData;
    };

    // Get metric display name
    const getMetricName = (metricField) => {
      const metric = metricOptions.find((m) => m.field === metricField);
      return metric ? metric.headerName : metricField;
    };

    // Custom tooltip for the chart
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div
            style={{
              backgroundColor: "#fff",
              padding: "10px",
              border: "1px solid #ccc",
            }}
          >
            <p>{`${payload[0].payload.name}`}</p>
            <p>{`${payload[0].payload.metric}: ${formatCurrency(
              payload[0].value
            )}`}</p>
          </div>
        );
      }
      return null;
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
        <Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
          <Grid container spacing={3}>
            {/* Filters */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Mağaza/Personel Karşılaştırma
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth>
                        <InputLabel>Mağaza Seç</InputLabel>
                        <Select
                          value={currentShop}
                          label="Mağaza Seç"
                          onChange={(e) => {
                            setCurrentShop(e.target.value);
                            if (e.target.value !== "all") setCurrentUser("all");
                          }}
                        >
                          {Allshops.map((shop) => (
                            <MenuItem key={shop} value={shop}>
                              {shop === "all" ? "Tüm Mağazalar" : shop}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth>
                        <InputLabel>Personel Seç</InputLabel>
                        <Select
                          value={currentUser}
                          label="Personel Seç"
                          onChange={(e) => {
                            setCurrentUser(e.target.value);
                            if (e.target.value !== "all") setCurrentShop("all");
                          }}
                        >
                          {AllPersonel.map((personnel) => (
                            <MenuItem key={personnel} value={personnel}>
                              {personnel === "all" ? "Tüm Personel" : personnel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <DatePicker
                          label="Başlangıç Tarihi"
                          value={startDate}
                          onChange={(newValue) => setStartDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          format="dd/MM/yyyy"
                        />
                        <DatePicker
                          label="Bitiş Tarihi"
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          renderInput={(params) => <TextField {...params} />}
                          format="dd/MM/yyyy"
                          minDate={startDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        startIcon={<CompareArrowsIcon />}
                        onClick={addToComparison}
                        sx={{ height: "100%" }}
                      >
                        Karşılaştırmaya Ekle
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Comparison Items */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Karşılaştırma Listesi
                  </Typography>
                  {comparisonItems.length === 0 ? (
                    <Typography
                      variant="body1"
                      sx={{ fontStyle: "italic", color: "text.secondary" }}
                    >
                      Karşılaştırmaya öğe eklemek için yukarıdaki filtreleri
                      kullanın
                    </Typography>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ flexWrap: "wrap", gap: 1 }}
                    >
                      {comparisonItems.map((item) => (
                        <Chip
                          key={item.id}
                          label={`${
                            item.shop === "all" ? "Tüm Mağazalar" : item.shop
                          }${item.user !== "all" ? ` (${item.user})` : ""} ${
                            item.startDate
                              ? `${item.startDate} - ${item.endDate}`
                              : ""
                          }`}
                          onDelete={() => removeComparisonItem(item.id)}
                          deleteIcon={<DeleteIcon />}
                          color="primary"
                          variant="outlined"
                        />
                      ))}
                    </Stack>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Metrics Selection */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Karşılaştırılacak Metrikler
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Gösterilecek Veriler</InputLabel>
                    <Select
                      multiple
                      value={selectedMetrics}
                      label="Gösterilecek Veriler"
                      onChange={(e) => setSelectedMetrics(e.target.value)}
                    >
                      {metricOptions.map((option) => (
                        <MenuItem key={option.field} value={option.field}>
                          {option.headerName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* Comparison Chart */}
            {comparisonItems.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Karşılaştırma Grafiği
                    </Typography>
                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={prepareChartData()}
                          margin={{ top: 20, right: 30, left: 40, bottom: 100 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="name"
                            angle={-45}
                            textAnchor="end"
                            height={80}
                          />
                          <YAxis />
                          <Tooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar dataKey="value" name="Değer" fill="#8884d8" />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {/* Comparison Table */}
            {comparisonItems.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Karşılaştırma Tablosu
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Mağaza/Personel</TableCell>
                            <TableCell>Tarih Aralığı</TableCell>
                            {selectedMetrics.map((metric) => (
                              <TableCell key={metric}>
                                {getMetricName(metric)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {comparisonItems.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>
                                {item.shop === "all"
                                  ? "Tüm Mağazalar"
                                  : item.shop}
                                {item.user !== "all" ? ` (${item.user})` : ""}
                              </TableCell>
                              <TableCell>
                                {item.startDate
                                  ? `${item.startDate} - ${item.endDate}`
                                  : "Tüm Tarihler"}
                              </TableCell>
                              {selectedMetrics.map((metric) => {
                                // Calculate total for the metric
                                let value = 0;
                                if (metric === "totalProfit") {
                                  value = item.summary.totalProfit;
                                } else if (metric === "PaymentFee") {
                                  value = item.summary.etsyDeposit;
                                } else if (metric === "totalSales") {
                                  value = item.summary.totalSales;
                                } else if (metric === "CSVSalesSummary") {
                                  value = item.summary.salesCount;
                                } else if (metric === "expenses") {
                                  value = item.summary.expenses;
                                } else {
                                  value = item.data.reduce(
                                    (sum, dataItem) =>
                                      sum + (parseFloat(dataItem[metric]) || 0),
                                    0
                                  );
                                }

                                return (
                                  <TableCell key={`${item.id}-${metric}`}>
                                    {metric.includes("Fee") ||
                                    metric.includes("Price") ||
                                    metric.includes("Cost") ||
                                    metric.includes("Profit") ||
                                    metric.includes("Money") ||
                                    metric.includes("Sales") ||
                                    metric.includes("expenses")
                                      ? formatCurrency(value)
                                      : value.toLocaleString()}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {/* Line Chart for Trend Comparison */}
            {comparisonItems.length > 0 && selectedMetrics.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Trend Karşılaştırması
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <InputLabel>Trend Metriği</InputLabel>
                      <Select
                        value={selectedMetrics[0]}
                        label="Trend Metriği"
                        onChange={(e) => setSelectedMetrics([e.target.value])}
                      >
                        {metricOptions.map((option) => (
                          <MenuItem key={option.field} value={option.field}>
                            {option.headerName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                          margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="StartDate"
                            type="category"
                            allowDuplicatedCategory={false}
                            tickFormatter={(date) =>
                              new Date(date).toLocaleDateString("tr-TR")
                            }
                          />
                          <YAxis />
                          <Tooltip
                            formatter={(value) => formatCurrency(value)}
                            labelFormatter={(label) =>
                              new Date(label).toLocaleDateString("tr-TR")
                            }
                          />
                          <Legend />

                          {comparisonItems.map((item, index) => {
                            const COLORS = [
                              "#1976d2",
                              "#2e7d32",
                              "#ed6c02",
                              "#9c27b0",
                              "#e91e63",
                            ];
                            return (
                              <Line
                                key={item.id}
                                data={item.data}
                                type="monotone"
                                dataKey={selectedMetrics[0]}
                                name={`${
                                  item.shop === "all"
                                    ? "Tüm Mağazalar"
                                    : item.shop
                                }${
                                  item.user !== "all" ? ` (${item.user})` : ""
                                }`}
                                stroke={COLORS[index % COLORS.length]}
                                strokeWidth={2}
                                dot={{ r: 4 }}
                                activeDot={{ r: 6 }}
                              />
                            );
                          })}
                        </LineChart>
                      </ResponsiveContainer>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      </LocalizationProvider>
    );
  };

  const calculateUserEarnings = () => {
    const userEarnings = {};

    userData.forEach((record) => {
      if (!userEarnings[record.UserName]) {
        userEarnings[record.UserName] = {
          argeHours: 0,
          argeEarnings: 0,
          otherHours: 0,
          otherEarnings: 0,
          totalHours: 0,
          totalEarnings: 0,
          shops: {},
        };
      }

      const [hours, minutes] = record.TimeSummary.split(":").map(Number);
      const durationHours = hours + minutes / 60;

      const userFee = userEffortsFees.find(
        (fee) => fee.userId === record.UserID
      );
      if (!userFee) return;

      if (record.Shop === "Arge") {
        const rate = parseFloat(userFee.effortType2Fee) || 0;
        userEarnings[record.UserName].argeHours += durationHours;
        userEarnings[record.UserName].argeEarnings += durationHours * rate;
      } else {
        const rate = parseFloat(userFee.effortType1Fee) || 0;
        userEarnings[record.UserName].otherHours += durationHours;
        userEarnings[record.UserName].otherEarnings += durationHours * rate;
        if (!userEarnings[record.UserName].shops[record.Shop]) {
          userEarnings[record.UserName].shops[record.Shop] = {
            hours: 0,
            earnings: 0,
          };
        }
        userEarnings[record.UserName].shops[record.Shop].hours += durationHours;
        userEarnings[record.UserName].shops[record.Shop].earnings +=
          durationHours * rate;
      }

      userEarnings[record.UserName].totalHours += durationHours;
      userEarnings[record.UserName].totalEarnings =
        userEarnings[record.UserName].argeEarnings +
        userEarnings[record.UserName].otherEarnings;
    });
    console.log(userEarnings);
    return userEarnings;
  };

  const [userEarnings, setUserEarnings] = useState([]);

  useEffect(() => {
    setUserEarnings(calculateUserEarnings());
  }, [userData, userEffortsFees]);

  const filteredEarnings = React.useMemo(() => {
    if (!startDate && !endDate) return userEarnings;
    console.log("startDate", startDate);
    console.log("endDate", endDate);

    const filtered = {};

    userData.forEach((record) => {
      const recordDate = new Date(record.EffortDate);
      recordDate.setHours(0, 0, 0, 0);

      let dateMatch = true;

      if (startDate) {
        const startDateObj = new Date(startDate);
        startDateObj.setHours(0, 0, 0, 0);
        dateMatch = dateMatch && recordDate >= startDateObj;
      }

      if (endDate) {
        const endDateObj = new Date(endDate);
        endDateObj.setHours(0, 0, 0, 0);
        dateMatch = dateMatch && recordDate <= endDateObj;
      }

      if (dateMatch) {
        const userName = record.UserName;
        if (!filtered[userName]) {
          filtered[userName] = {
            argeHours: 0,
            argeEarnings: 0,
            otherHours: 0,
            otherEarnings: 0,
            totalHours: 0,
            totalEarnings: 0,
          };
        }

        const hours =
          parseFloat(record.TimeSummary.split(":")[0]) +
          parseFloat(record.TimeSummary.split(":")[1]) / 60;

        const userFee = userEffortsFees.find(
          (fee) => fee.userId === record.UserID
        );
        if (!userFee) return;

        if (record.Shop === "Arge") {
          const argeRate = parseFloat(userFee.effortType2Fee) || 0;
          filtered[userName].argeHours += hours;
          filtered[userName].argeEarnings += hours * argeRate;
        } else {
          const shopRate = parseFloat(userFee.effortType1Fee) || 0;
          filtered[userName].otherHours += hours;
          filtered[userName].otherEarnings += hours * shopRate;
        }

        filtered[userName].totalHours += hours;
        filtered[userName].totalEarnings =
          filtered[userName].argeEarnings + filtered[userName].otherEarnings;
      }
    });
    return filtered;
  }, [userData, userEffortsFees, startDate, endDate]);

  const UserEarningsScreen = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div style={{ padding: "24px" }}>
          <Grid container spacing={3}>
            {/* Date Range Selection */}
            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Tarih Aralığı
                  </Typography>

                  {/* Tarih Seçimi ve Butonu Yan Yana Getiren Satır */}
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <DatePicker
                        label="Başlangıç Tarihi"
                        value={startDate}
                        onChange={setStartDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <DatePicker
                        label="Bitiş Tarihi"
                        value={endDate}
                        onChange={setEndDate}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFetchData}
                        disabled={loading}
                        sx={{ minWidth: 180, height: 56 }}
                      >
                        {loading ? "Yükleniyor..." : "Verileri Getir"}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Bilgilendirme Mesajını Butonun Altına Uzun Yazmak İçin */}
                  <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        color={userData.length === 0 ? "error" : "success"}
                        align="center"
                      >
                        {userData.length === 0
                          ? "Lütfen Başlangıç ve Bitiş Tarihlerini Seçiniz ve Verileri Getir Butonuna Basınız"
                          : "Farklı Tarihte Veri Seçimi Yapabilirsiniz"}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* User Filter */}
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kullanıcı Filtresi
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Kullanıcı Seç</InputLabel>
                    <Select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      label="Kullanıcı Seç"
                    >
                      <MenuItem value="all">Tüm Kullanıcılar</MenuItem>
                      {Object.keys(filteredEarnings).map((userName) => (
                        <MenuItem key={userName} value={userName}>
                          {userName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* Earnings Table */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Kazanç Özeti
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginBottom: "16px" }}
                    onClick={exportToExcel}
                  >
                    Excel'e Aktar
                  </Button>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Kullanıcı</TableCell>
                          <TableCell>Arge Saatleri</TableCell>
                          <TableCell>Arge Kazancı</TableCell>
                          <TableCell>Diğer Saatler</TableCell>
                          <TableCell>Diğer Kazanç</TableCell>
                          <TableCell>Toplam Saat</TableCell>
                          <TableCell>Toplam Kazanç</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(filteredEarnings)
                          .filter(
                            ([userName]) =>
                              selectedUser === "all" ||
                              userName === selectedUser
                          )
                          .map(([userName, earnings]) => (
                            <TableRow key={userName}>
                              <TableCell>{userName}</TableCell>
                              <TableCell>
                                {earnings.argeHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.argeEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.otherHours.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                ${earnings.otherEarnings.toFixed(2)}
                              </TableCell>
                              <TableCell>
                                {earnings.totalHours.toFixed(2)}
                              </TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>
                                ${earnings.totalEarnings.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}

                        {/* Alt toplamlar */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </LocalizationProvider>
    );
  };

  const exportToExcel = () => {
    const data = Object.entries(filteredEarnings)
      .filter(
        ([userName]) => selectedUser === "all" || userName === selectedUser
      )
      .map(([userName, earnings]) => ({
        Kullanıcı: userName,
        "Arge Saatleri": earnings.argeHours.toFixed(2),
        "Arge Kazancı": `$${earnings.argeEarnings.toFixed(2)}`,
        "Diğer Saatler": earnings.otherHours.toFixed(2),
        "Diğer Kazanç": `$${earnings.otherEarnings.toFixed(2)}`,
        "Toplam Saat": earnings.totalHours.toFixed(2),
        "Toplam Kazanç": `$${earnings.totalEarnings.toFixed(2)}`,
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);

    const columnsArray = Object.keys(data[0]);

    const columnWidths = columnsArray.map((col) => ({ wch: col.length + 2 }));
    worksheet["!cols"] = columnWidths;

    const headerStyle = {
      fill: {
        fgColor: { rgb: "90EE90" }, // Açık yeşil renk
        type: "pattern",
        patternType: "solid",
      },
      font: {
        bold: true,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    // Her kolon başlığına style uygula
    columnsArray.forEach((col, idx) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: idx });
      worksheet[cellRef].s = headerStyle;
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kazanç Özeti");

    // Dosyayı kaydet
    XLSX.writeFile(workbook, "Kazanç_Özeti.xlsx");
  };
  const CustomScreenShotControl = () => {
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await axios.get(path + "/api/GetActivePersonnels");
          const parsedData = response.data;
          console.log(parsedData);
          setUsers(parsedData);
        } catch (error) {
          console.error("Error fetching shop data:", error);
        }
      };
      fetchUsers();
    }, []);

    const [selectedUser, setSelectedUser] = useState(null);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [screenshots, setScreenshots] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(60);

    const handleFetchScreenshots = async () => {
      try {
        setLoading(true);
        setError("");
        setPage(0);

        const startDateTime = startDate.format("YYYY-MM-DD HH:mm:ss");
        const endDateTime = endDate.format("YYYY-MM-DD HH:mm:ss");

        const response = await axios.post(`${path}/api/GetScreenShots`, {
          startDate: startDateTime,
          endDate: endDateTime,
          userName: selectedUser,
        });

        setScreenshots(response.data);
      } catch (err) {
        setError("Ekran görüntüleri yüklenirken bir hata oluştu.");
        console.error("Error fetching screenshots:", err);
      } finally {
        setLoading(false);
      }
    };

    const pageCount = Math.ceil(screenshots.length / itemsPerPage);
    const currentPageItems = screenshots.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );
    console.log(screenshots);
    return (
      <Box sx={{ p: 3 }}>
        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              alignItems: "center",
              mb: 3,
            }}
          >
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Kullanıcı</InputLabel>
              <Select
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
                label="Kullanıcı"
              >
                {users.map((user) => (
                  <MenuItem key={user.UserID} value={user.Username}>
                    {user.Username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={setStartDate}
                sx={{ minWidth: 250 }}
                ampm={false}
              />
              <DateTimePicker
                label="Bitiş Tarihi"
                value={endDate}
                onChange={setEndDate}
                sx={{ minWidth: 250 }}
                ampm={false}
              />
            </LocalizationProvider>

            <Button
              variant="contained"
              onClick={handleFetchScreenshots}
              sx={{ height: 56 }}
            >
              Ekran Görüntülerini Getir
            </Button>
          </Box>

          {screenshots.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography>Toplam: {screenshots.length} görüntü</Typography>
              <Pagination
                count={pageCount}
                page={page + 1}
                onChange={(e, newPage) => setPage(newPage - 1)}
                color="primary"
              />
            </Box>
          )}
        </Paper>

        <Box sx={{ mt: 4 }}>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={400}
            >
              <Typography sx={{ ml: 2 }}>Yükleniyor...</Typography>
            </Box>
          ) : error ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="error.main"
            >
              {error}
            </Box>
          ) : screenshots.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="text.secondary"
            >
              Bu zaman aralığında ekran görüntüsü bulunmamaktadır.
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxHeight: "1000px",
                overflow: "auto",
                p: 2,
              }}
            >
              {currentPageItems.map((screenshot, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "120%",
                    maxWidth: 1000,
                    border: "1px solid",
                    borderColor: "divider",
                    borderRadius: 2,
                    p: 2,
                    boxShadow: 1,
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxHeight: 900,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      src={screenshot.ScreenshotPath}
                      alt={`Ekran Görüntüsü ${index + 1}`}
                      sx={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                      onError={(e) => {
                        e.target.src = "/api/placeholder/400/300";
                      }}
                    />
                  </Box>
                  <Typography variant="caption" color="text.secondary" mt={1}>
                  {
                    (screenshot.UploadDateString)}
                  </Typography>

                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const menuItems = [
    { text: "Kullanıcı Raporları", icon: <PeopleIcon /> },
    { text: "Shop Raporları", icon: <StoreIcon /> },
    { text: "Karşılaştırmalı Raporlar", icon: <BarChartIcon /> },
    { text: "Kullanıcı Ücretleri", icon: <AttachMoneyIcon /> },
    { text: "Kullanıcı Takip", icon: <VisibilityIcon /> },
  ];

  return (
    <>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          bgcolor: "rgb(180, 190, 190)",
          p: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            open={open}
            style={{ backgroundColor: "#405D72" }}
          >
            <Toolbar style={{ backgroundColor: "#405D72" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={[
                  {
                    mr: 2,
                  },
                  open && { display: "none" },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Kullanıcı Raporları
              </Typography>

              <IconButton
                onClick={() => navigate("/")}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  padding: "5px 15px",
                  borderRadius: "25px",
                  backgroundColor: "#fff",
                  ml: "auto",
                  "&:hover": {
                    backgroundColor: "#ccc",
                  },
                }}
              >
                <Avatar
                  src="/Logos/HORSE HEAD.svg"
                  alt="Ana Menü"
                  sx={{ width: 50, height: 50 }}
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#000",
                  }}
                >
                  Ana Menü
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
                backgroundColor: "#DDE7F2",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <Toolbar>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List>
              {menuItems.map(({ text, icon }) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(text)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Drawer>
          ;
          <Box component="main" sx={{ flexGrow: 1, p: 3, maxHeight: "100vh" }}>
            <Toolbar />
            {/* Menüye göre dinamik içerik */}
            {selectedMenu === "Kullanıcı Raporları" && (
              <Typography>
                <UserDetailReports />
              </Typography>
            )}
            {selectedMenu === "Shop Raporları" && (
              <Typography>
                <ShopData />
              </Typography>
            )}
            {/* Diğer menü öğelerine içerik eklenebilir */}
            {selectedMenu === "Karşılaştırmalı Raporlar" && (
              <Typography>
                {user.Admin === 1 ? <CompareShops /> : "Yetkiniz yok!"}
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Ücretleri" && (
              <Typography>
                {user.Admin === 1 || user.Username === "osman kale" ? (
                  <UserEarningsScreen />
                ) : (
                  "Yetkiniz yok!"
                )}
              </Typography>
            )}
            {selectedMenu === "Kullanıcı Takip" && (
              <Typography>
                {user.Admin === 1 ? (
                  <CustomScreenShotControl />
                ) : (
                  "Yetkiniz yok!"
                )}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserReports;
